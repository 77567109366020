import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ContactUsContent from "./ContactUsContent";
import $ from "jquery";
class ContactUs extends Component {
  constructor(props) {
    super(props);
    var getUrl = window.location;
    var finalurl = getUrl.protocol + "//" + getUrl.hostname + "/firstserveukadmincms/";
    this.state = {
      baseUrl: finalurl,
      isLoaded: false,
      data: [],
      message: ""

    };
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Washing machine repair London - First Serve UK</title>
          <meta
            name="description"
            content="Washing Machine Repairs London - First Serve UK. Same/Next Day Service, 24-Hour Online Booking, Long Guarantee and Special Offers"
          />
          <link rel="canonical" href="https://firstserveuk.co.uk/contact-us" />
        </Helmet>
        <div className="page-title title-left solid-bg page-title-responsive-enabled">
          <div className="wf-wrap">
            <div className="page-title-head hgroup">
              <h1>Contact Us</h1>
            </div>
            <div className="page-title-breadcrumbs">
              <div className="assistive-text">You are here:</div>
              <ol className="breadcrumbs text-small">
                <li typeof="v:Breadcrumb">
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Contact Us</li>
              </ol>
            </div>
          </div>
        </div>
        <ContactUsContent parentStates={this.state} />
        {/* <MainJS /> */}
        {/* <Script url="./assets/js/jquery.js" />
        <Script url="./assets/js/main.js" /> */}
      </React.Fragment>
    );
  }
  componentDidMount() {
    this.getContactData();
  }
  getContactData = () => {
    let insertUrl = this.state.baseUrl + "getContactUsData";
    new Promise(function (resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST"
      }).then(
        function (data) {
          resolve(data);
        },
        function (err) {
          reject(err);
        }
      );
    })
      .then(result => {

        this.setState({
          data: result[0],
          isLoaded: true
        });
        // console.log(result);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          message: "No news Found!",
          isLoaded: true
        });
      });
  };

}

export default ContactUs;
