import React from "react";
import Booking from "../MUC/booking.jsx";

const CookersContent = () => {
  return (
    <React.Fragment>
      <div id="main" className="sidebar-right sidebar-divider-off">
        <div className="main-gradient" />
        <div className="wf-wrap">
          <div className="wf-container-main">
            <div id="content" className="content" role="main">

<section className="vc_section">
   <div className="vc_row wpb_row vc_row-fluid height-margin">
      <div className="wpb_column vc_column_container vc_col-sm-3">
         <div className="vc_column-inner ">
            <div className="wpb_wrapper">
               <div className="wpb_single_image wpb_content_element vc_align_left">
                  <figure className="wpb_wrapper vc_figure">
                            <div className="vc_single_image-wrapper   vc_box_border_grey"><img width={"422"} height={"294"} src="../wp-content/uploads/2018/02/central-heating-img.jpg" className="vc_single_image-img attachment-full" alt="First Serve UK" srcSet="https://firstserveuk.co.uk/wp-content/uploads/2018/02/central-heating-img.jpg 422w, https://firstserveuk.co.uk/wp-content/uploads/2018/02/central-heating-img-300x209.jpg 300w" sizes="(max-width: 422px) 100vw, 422px" data-dt-location="../central-heating-img/index.html" /></div>
                  </figure>
               </div>
            </div>
         </div>
      </div>
      <div className="wpb_column vc_column_container vc_col-sm-9">
         <div className="vc_column-inner ">
            <div className="wpb_wrapper">
               <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                     <p>All central heating work are undertaken by qualified and professional engineers who are Gas Safe engineers.</p>
                     <p>Such as central heating repairs, central heating controls, central heating pumps, boiler installation, boiler fault finding, repairs, and servicing, power flushing, installing radiators, replacing them, and repairing and servicing them, gas testing, repairs, immersion heaters, repair and installation of cylinders.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

<div className="vc_row wpb_row vc_row-fluid">
   <div className="wpb_column vc_column_container vc_col-sm-12">
      <div className="vc_column-inner ">
         <div className="wpb_wrapper">
            <div className="wpb_text_column wpb_content_element ">
               <div className="wpb_wrapper">
                  <div id={1} className="widgets_on_page wop_tiny1  wop_small1  wop_medium1  wop_large1  wop_wide1">
                     <ul>
                        <li id="text-7" className="widget widget_text">
                           <div className="textwidget">
                              <ul>
                                 <li><a className="cta-button" title href="http://firstserveuk.simplybook.it/v2/#book" target="_blank" rel="noopener noreferrer">Same Day Service</a></li>
                                 <li><a className="cta-button cta-button-2" title href="http://firstserveuk.simplybook.it/v2/#book" target="_blank" rel="noopener noreferrer">Next Day Service</a></li>
                              </ul>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

            </div>
            <aside id="sidebar" className="sidebar bg-under-widget">
              <div className="sidebar-content widget-divider-off">
                <section id="text-9" className="widget widget_text" style={{    background: "#014485",
    borderRadius: "5px"}}>
                  <div className="textwidget" style={{ marginBottom: "10px"}}>
                    <div className="sidebar-cta">
                      <h2 style={{color:"#ffffff"}}><strong style={{color:"#ffffff", fontWeight: "700"}}>Same Day/Next Day Service!</strong> FREE Extra Repair With <a href="http://firstserveuk.simplybook.it/v2/" target="_blank" style={{color: '#fff', textDecoration: 'underline'}} rel="noopener noreferrer">Online Bookings</a></h2>
                      <ul style={{color:"#ffffff"}}>
                        <li>Local Washing Machine Repair Specialists</li>
                        <li>Same Day or Next Day Service</li>
                        <li>1 Year Guarantee</li>
                      </ul>
                      <p><a style={{ backgroundColor: "#ffb536", padding:"20px", margin: "0", display: "block", color: "#000000",
                    backgroundImage: "linear-gradient(to bottom,rgba(255,255,255,.2) 0,rgba(255,255,255,.01) 100%)",
                    backgroundRepeat: "repeat-x", textDecoration: "none", borderRadius: "7px", maxWidth: "360px",
                    lineHeight: "1.2", textAlign: "center", cursor: "pointer",
                    font: "normal 15px/23px `Arial`,Helvetica,Arial,Verdana,sans-serif",
                    wordSpacing: "normal"}} href="http://firstserveuk.simplybook.it/v2/" target="_blank" rel="noopener noreferrer"><strong style={{color:"#000000", fontSize: "26px"}}>07984 670 782</strong> <br/><span style={{textDecoration: 'underline',  fontSize: "20px"}}>24-Hour Online Booking</span></a></p>
                    </div>
                  </div>
                </section>
                <section id="presscore-custom-menu-1-2" className="widget widget_presscore-custom-menu-1">
                  <div className="widget-title">Services</div>
                  <ul className="custom-menu">
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2628 first"><a href="https://www.google.com/maps/place/First+Serve+UK+Washing+Machine+Repairs/@51.4868865,-0.2129854,11z/data=!3m1!4b1!4m7!3m6!1s0x0:0xa6f9709d40c985f2!8m2!3d51.4869699!4d-0.072903!9m1!1b1" target="_blank" rel="noopener noreferrer">Latest Reviews</a></li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2667"><a href="http://firstserveuk.simplybook.it/v2/" target="_blank" rel="noopener noreferrer">Book One, Get One FREE</a></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2712"><a href="../services/washing-machine-repairs/index.html">Washing Machine Repairs</a></li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2621"><a href="../first-serve-uk/tumbler-and-condenser-dryers/index.html">Tumbler and Condenser Dryers</a></li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2622"><a href="https://www.espares.co.uk/">Washing Machine Spares</a></li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2624"><a href="../first-serve-uk/electric-cooker-and-oven-repa/index.html">Cookers</a></li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2625"><a href="../first-serve-uk/blocked-sinks-and-drains/index.html">Sink Unblocking</a></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2627"><a href="../dishwashers/index.html">Dishwashers</a></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2626"><a href="../central-heating/index.html">Central Heating</a></li>
                  </ul>
                </section>
                <section id="text-3" className="widget widget_text">
                  <div className="textwidget">
                    <h4 style={{color: '#fff', background: '#013c7a'}}>Contact Us</h4>
                    <div role="form" className="wpcf7" id="wpcf7-f4-o1" lang="en-US" dir="ltr">
                      <div className="screen-reader-response" />
                      <form action="https://firstserveuk.co.uk/about-us/#wpcf7-f4-o1" method="post" className="wpcf7-form" noValidate="novalidate">
                        <div style={{display: 'none'}}> 
                        <input type="hidden" name="_wpcf7" defaultValue={4} /> 
                        <input type="hidden" name="_wpcf7_version" defaultValue="5.1.1" /> 
                        <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" /> 
                        <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f4-o1" /> 
                        <input type="hidden" name="_wpcf7_container_post" defaultValue={0} /> 
                        <input type="hidden" name="g-recaptcha-response" defaultValue />
                        </div>
                        <p>
                          <span className="wpcf7-form-control-wrap your-name">
                            <input type="text" name="your-name" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Full Name" />
                          </span>
                            <br /> 
                          <span className="wpcf7-form-control-wrap your-email">
                            <input type="text" name="your-email" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"  placeholder="Email Address" />
                          </span>
                          <br /> 
                          <span className="wpcf7-form-control-wrap your-Telephone">
                            <input type="text" name="your-Telephone" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"placeholder="Telephone Number" />
                          </span>
                          <br /> 
                          <span className="wpcf7-form-control-wrap makeModelofAppliance">
                            <input type="text" name="makeModelofAppliance" size={40} className="wpcf7-form-control wpcf7-text"  placeholder="Make Model of Appliance" />
                          </span>
                          <br /> 
                          <span className="wpcf7-form-control-wrap NatureofFault">
                            <input type="text" name="NatureofFault"  size={40} className="wpcf7-form-control wpcf7-text"  placeholder="Nature of Fault" />
                          </span>
                          <br /> 
                          <span className="wpcf7-form-control-wrap Comment">
                            <textarea name="Comment" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea"  placeholder="Comment..." />
                          </span>
                        </p>
                        <p><input type="submit" defaultValue="SUBMIT" className="wpcf7-form-control wpcf7-submit" /></p>
                        <div className="wpcf7-response-output wpcf7-display-none" />
                      </form>
                    </div>
                  </div>
                </section>
              </div>
            </aside>
          
          </div>
        </div>
        <Booking />
      </div>
    </React.Fragment>
  );
};
export default CookersContent;
