import React from "react";

const Twitter = () => {
  return (
    <React.Fragment>
      <div className="vc_column-inner ">
        <div className="wpb_wrapper">
          <div
            id="ultimate-heading-55735d39c86d9f5e7"
            className="uvc-heading ult-adjust-bottom-margin ultimate-heading-55735d39c86d9f5e7 uvc-5949 mdl_twitter "
            data-hspacer="no_spacer"
            data-halign="left"
            style={{ textAlign: "left" }}
          >
            <div className="uvc-heading-spacer no_spacer" style={{}} />
            <div
              className="uvc-main-heading ult-responsive"
              data-ultimate-target=".uvc-heading.ultimate-heading-55735d39c86d9f5e7 h2"
              data-responsive-json-new='{"font-size":"desktop:28px;","line-height":""}'
            >
              <h2 style={{ fontWeight: "bold", color: "#027cb6" }}>Twitter</h2>
            </div>
          </div>
          <div
            id="ultimate-heading-57075d39c86d9f7fe"
            className="uvc-heading ult-adjust-bottom-margin ultimate-heading-57075d39c86d9f7fe uvc-4924 "
            data-hspacer="no_spacer"
            data-halign="left"
            style={{ textAlign: "left" }}
          >
            <div className="uvc-heading-spacer no_spacer" style={{}} />
            <div
              className="uvc-sub-heading ult-responsive"
              data-ultimate-target=".uvc-heading.ultimate-heading-57075d39c86d9f7fe .uvc-sub-heading "
              data-responsive-json-new='{"font-size":"","line-height":""}'
              style={{ fontWeight: "normal" }}
            >
              <a
                href="https://twitter.com/1stServeUK"
                target="_blank"
                rel="noopener noreferrer"
              > 
                <img
                  className="alignnone size-full wp-image-2879"
                  style={{ height: "387px", width: "auto" }}
                  src="./assets/images/Twitter-feed2.png"
                  alt="Twitter"
                  srcSet="https://firstserveuk.co.uk/wp-content/uploads/2019/01/Twitter-feed2.png 256w, https://firstserveuk.co.uk/wp-content/uploads/2019/01/Twitter-feed2-188x300.png 188w"
                  sizes="(max-width: 256px) 100vw, 256px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
    </React.Fragment>
  );
};
export default Twitter;
