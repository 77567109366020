import React from "react";

const Form = () => {
  return (
    <React.Fragment>
      <div className="vc_column-inner ">
        <div className="wpb_wrapper">
          <div className="wpb_widgetised_column wpb_content_element sidebar-content solid-bg">
            <div className="wpb_wrapper">
              <section id="text-10" className="widget widget_text">
                <div className="textwidget">
                  <h4
                    style={{
                      fontSize: "24px",
                      color: "#fff",
                      background: "#013c7a"
                    }}
                  >
                    Earn Money with Us
                  </h4>
                  <p style={{ color: "#fff", background: "#013c7a" }}>
                    {" "}
                    Get paid £20 referring your friends and family who has a
                    repair done by us.
                  </p>
                  <div
                    role="form"
                    className="wpcf7"
                    id="wpcf7-f2759-p1731-o1"
                    lang="en-GB"
                    dir="ltr"
                  >
                    <div className="screen-reader-response" />
                    <form
                      action="https://firstserveuk.co.uk/#wpcf7-f2759-p1731-o1"
                      method="post"
                      className="wpcf7-form"
                      noValidate="novalidate"
                    >
                      <div style={{ display: "none" }}>
                        {" "}
                        <input
                          type="hidden"
                          name="_wpcf7"
                          value={"2759"}
                        />{" "}
                        <input
                          type="hidden"
                          name="_wpcf7_version"
                          value="5.1.1"
                        />{" "}
                        <input
                          type="hidden"
                          name="_wpcf7_locale"
                          value="en_GB"
                        />{" "}
                        <input
                          type="hidden"
                          name="_wpcf7_unit_tag"
                          value="wpcf7-f2759-p1731-o1"
                        />{" "}
                        <input
                          type="hidden"
                          name="_wpcf7_container_post"
                          value={"1731"}
                        />{" "}
                        <input
                          type="hidden"
                          name="g-recaptcha-response"
                          value
                        />
                      </div>
                      <p>
                        <label style={{ color: "#fff" }}>
                          {" "}
                          Your Name (required)
                          <br />{" "}
                          <span className="wpcf7-form-control-wrap your-name">
                            <input
                              type="text"
                              name="your-name"
                              size={40}
                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Enter Name"
                            />
                          </span>{" "}
                        </label>
                      </p>
                      <p>
                        <label style={{ color: "#fff" }}>
                          {" "}
                          Your Email (required)
                          <br />{" "}
                          <span className="wpcf7-form-control-wrap your-email">
                            <input
                              type="email"
                              name="your-email"
                              placeholder="Enter Email"
                              size={40}
                              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                              aria-required="true"
                              aria-invalid="false"
                            />
                          </span>{" "}
                        </label>
                      </p>
                      <p>
                        <label style={{ color: "#fff" }}>
                          {" "}
                          Your Friend's Postcode
                          <br />{" "}
                          <span className="wpcf7-form-control-wrap your-friend">
                            <input
                              type="text"
                              name="your-friend"
                              placeholder="Enter Postcode"
                              size={40}
                              className="wpcf7-form-control wpcf7-text"
                              aria-invalid="false"
                            />
                          </span>{" "}
                        </label>
                      </p>
                      <p>
                        <input
                          type="submit"
                          defaultValue="Send"
                          className="wpcf7-form-control wpcf7-submit"
                        />
                      </p>
                      <div className="wpcf7-response-output wpcf7-display-none" />
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Form;
