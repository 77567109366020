import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AboutUsContent from "./AboutUsContent";
import $ from "jquery";
import GetbaseUrl from "../base_url";

class AboutUs extends Component {

  constructor(props) {
    var url = GetbaseUrl();
    super(props);
    this.state = {
      baseUrl: url,
      isLoaded: false,
      message:"",
      mainAboutUsData : [],
      LogoSliderData : []
    };
  }
  render() { 
    const {mainAboutUsData,LogoSliderData,isLoaded}=this.state;
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us | First Serve UK</title>
          <meta
            name="description"
            content="First Serve UK. Same/Next Day Service, 24-Hour Online Booking, Long Guarantee
            and Special Offers"
          />
          <link rel="canonical" href="https://firstserveuk.co.uk/about-us" />
        </Helmet>
        <div className="page-title title-left solid-bg page-title-responsive-enabled">
          <div className="wf-wrap">
            <div className="page-title-head hgroup">
              <h1>About Us</h1>
            </div>
            <div className="page-title-breadcrumbs">
              <div className="assistive-text">You are here:</div>
              <ol className="breadcrumbs text-small">
                <li typeof="v:Breadcrumb">
                  <Link to="/">Home</Link>
                </li>
                <li className="current">About Us</li>
              </ol>
            </div>
          </div>
        </div>
        <AboutUsContent data={mainAboutUsData} datalogo={LogoSliderData} isLoaded={isLoaded} />
        {/* <MainJS /> */}
        {/* <Script url="./assets/js/jquery.js" />
        <Script url="./assets/js/main.js" /> */}
      </React.Fragment>
    );
  }
  componentDidMount()
  {
    this.getAboutUsData();
  }

  getAboutUsData = () => {
    // console.log(this.state.baseUrl)
    let insertUrl = this.state.baseUrl + "getAboutUsData";
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST"
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        
        this.setState({
          mainAboutUsData:result["mainContent"],
          LogoSliderData:result["logoSlider"],
          isLoaded:true
        });
       
        // console.log(result);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          message: "No About Us Found!",
          isLoaded: true
        });
      });
  };
}

export default AboutUs;
