import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./HOC/Layout/Layout";
import Home from "./components/public/Home/Home";
import AboutUs from "./components/public/AboutUs/AboutUs";
import TrainingArticle from "./components/public/AboutUs/TrainingArticle";
import Training from "./components/public/AboutUs/Training";
import AreasWeCover from "./components/public/AreasWeCover/AreasWeCover";
import WhichWashingMachine from "./components/public/AreasWeCover/WhichWashingMachine";
import WasherDryerEfficiency from "./components/public/AreasWeCover/WasherDryerEfficiency";
import WasherLondon from "./components/public/AreasWeCover/WasherLondon";
import WasherLondonW2 from "./components/public/AreasWeCover/WasherLondonW2";
import WasherLondonW4 from "./components/public/AreasWeCover/WasherLondonW4";
import WashingChelsea from "./components/public/AreasWeCover/WashingChelsea";
import WashingSW1 from "./components/public/AreasWeCover/WashingSW1";
import WashingSW10 from "./components/public/AreasWeCover/WashingSW10";
import BuyAppliances from "./components/public/AreasWeCover/BuyAppliances";
import SaveMoney from "./components/public/AreasWeCover/SaveMoney";
import Services from "./components/public/Services/Services";
import WashingRepair from "./components/public/Services/WashingRepair";
import Tumbler from "./components/public/Services/Tumbler";
import Cookers from "./components/public/Services/Cookers";
import RepairLondon from "./components/public/Services/RepairLondon";
import RepairKensington from "./components/public/Services/RepairKensington";
import SinkUnblocking from "./components/public/Services/SinkUnblocking";
import Dishwashers from "./components/public/Services/Dishwashers";
import CentralHeating from "./components/public/Services/CentralHeating";
import Testimonials from "./components/public/Testimonials/Testimonials";
import ContactUs from "./components/public/ContactUs/ContactUs";
import News from "./components/public/News/News";
import ReadMore from "./components/public/News/ReadMore";

export class Routes extends Component {
  state = {
    viewType: "publicSite"
  };
  render() {
    return (
      <div>
        <Layout type={this.state.viewType}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about-us" exact component={AboutUs} />
            <Route path="/training-article" exact component={TrainingArticle} />
            <Route path="/training" exact component={Training} />
            <Route path="/areas-we-cover" exact component={AreasWeCover} />
            <Route
              path="/which-washing-machine"
              exact
              component={WhichWashingMachine}
            />
            <Route
              path="/washer-dryer-efficiency"
              exact
              component={WasherDryerEfficiency}
            />
            <Route path="/e-store" exact component={BuyAppliances} />
            <Route path="/save-money" exact component={SaveMoney} />
            <Route path="/services" exact component={Services} />
            <Route
              path="/washing-machine-repairs-in-w1-london"
              exact
              component={WasherLondon}
            />
            <Route
              path="/washing-machine-repairs-in-w2-london"
              exact
              component={WasherLondonW2}
            />
            <Route
              path="/washing-machine-repairs-in-chiswick-w4-london"
              exact
              component={WasherLondonW4}
            />
            <Route
              path="/washing-machine-repairs-chelsea"
              exact
              component={WashingChelsea}
            />
            <Route
              path="/sw1w-and-sw1v-washing-machine-repairs"
              exact
              component={WashingSW1}
            />
            <Route
              path="/sw10-washing-machine-repairs"
              exact
              component={WashingSW10}
            />
            <Route
              path="/washing-machine-repairs"
              exact
              component={WashingRepair}
            />
            <Route
              path="/tumbler-and-condenser-dryers"
              exact
              component={Tumbler}
            />
            <Route
              path="/electric-cooker-and-oven-repa"
              exact
              component={Cookers}
            />
            <Route
              path="/blocked-sinks-and-drains"
              exact
              component={SinkUnblocking}
            />
            <Route
              path="/washing-machine-repair-london"
              exact
              component={RepairLondon}
            />
            <Route
              path="/washing-machine-repair-kensington"
              exact
              component={RepairKensington}
            />
            <Route path="/dishwashers" exact component={Dishwashers} />
            <Route path="/central-heating" exact component={CentralHeating} />
            <Route path="/testimonials" exact component={Testimonials} />
            <Route path="/contact-us" exact component={ContactUs} />
            <Route path="/news" exact component={News} />
            <Route path="/:readmore" exact component={ReadMore} />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default Routes;
