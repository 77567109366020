import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import TrainingContent from "./TrainingContent";
import $ from "jquery";
import GetbaseUrl from "../base_url";

class Training extends Component {
  constructor(props) {
    var url = GetbaseUrl();
    super(props);
    this.state = {
      baseUrl: url,
      isLoaded: false,
      message: "",
      TrainningData: []
    };
  }
  render() {
    const { TrainningData, isLoaded } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Washing Machine Training - First Serve UK</title>
          <meta
            name="description"
            content="Washing Machine Repairs London - First Serve UK. Same/Next Day Service, 24-Hour Online Booking, Long Guarantee and Special Offers"
          />
          <link rel="canonical" href="https://firstserveuk.co.uk/training" />
        </Helmet>
        <div className="page-title title-left solid-bg page-title-responsive-enabled">
          <div className="wf-wrap">
            <div className="page-title-head hgroup">
              <h1>Traning</h1>
            </div>
            <div className="page-title-breadcrumbs">
              <div className="assistive-text">You are here:</div>
              <ol className="breadcrumbs text-small">
                <li typeof="v:Breadcrumb">
                  <Link to="/">Home</Link>
                </li>
                <li typeof="v:Breadcrumb">
                  <Link to="/about-us">About Us</Link>
                </li>
                <li className="current">Traning</li>
              </ol>
            </div>
          </div>
        </div>
        <TrainingContent data={TrainningData} isLoaded={isLoaded} />
        {/* <MainJS /> */}
        {/* <Script url="./assets/js/jquery.js" />
        <Script url="./assets/js/main.js" /> */}
      </React.Fragment>
    );
  }
  componentDidMount() {
    this.getTraningData();
  }

  getTraningData = () => {
    // console.log(this.state.baseUrl)
    let insertUrl = this.state.baseUrl + "getTraningData";
    new Promise(function (resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST"
      }).then(
        function (data) {
          resolve(data);
        },
        function (err) {
          reject(err);
        }
      );
    })
      .then(result => {

        this.setState({
          TrainningData: result["mainContent"],
          isLoaded: true
        });

        // console.log(result);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          message: "No Trainning Found!",
          isLoaded: true
        });
      });
  };
}

export default Training;
