import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };
  commingSoon = () => {
    alert("Comming Soon...");
  };
  render() {
    return (
      <React.Fragment>
        <ul className="footer_cta_wrapper">
          <li id="text-6" className="widget widget_text">
            <div className="textwidget">
              <div className="vc_column-inner ">
                <div className="first_image">
                  <h4 className="first_image-text-1">
                    <a
                      href="https://www.google.com/maps/place/First+Serve+UK/@51.4868865,-0.2129854,11z/data=!3m1!4b1!4m5!3m4!1s0x0:0xa6f9709d40c985f2!8m2!3d51.4869699!4d-0.072903"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read What Our Clients Have To Say About Us
                    </a>
                  </h4>
                  <p>&nbsp;</p>
                </div>
                <div className="second_image">
                  <h4 className="first_image-text-2">E-store</h4>
                  <p>&nbsp;</p>
                </div>
                <div className="third-image">
                  <h6 className="first_image-text-3">
                    <a href="#" onClick={this.toggle}>
                      Download
                      <br /> Brochures, Publications and E Books
                    </a>
                  </h6>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </li>
          <li id="text-5" className="widget widget_text">
            <div className="textwidget">
              <h3
                style={{
                  background: "rgb(31, 54, 92)",
                  color: "rgb(255, 255, 255)",
                  fontSize: "32px"
                }}
              >
                {/* {this.props.loadStatus? this.props.data.heading1 :""} */}
                Book Your Washing
                <br />
                Machine Repair By Calling Us Now
                {/* {this.props.loadStatus ? this.props.data.heading2 : ""} */}
              </h3>
              <p
                style={{
                  background: "rgb(31, 54, 92)",
                  fontSize: "30px",
                  margin: "15px 0px",
                  fontStyle: "italic",
                  color: "rgb(255, 255, 255)"
                }}
                className="inline-this-text"
              >
                {/* {this.props.loadStatus?  this.props.data.sub_heading1 :""} */}
                LOCAL SPECIALISTS IN
                <br />
                {/* {this.props.loadStatus ? this.props.data.sub_heading2 : ""} */}
                SAME DAY OR NEXT DAY SERVICE
              </p>
              <h4 style={{ color: "rgb(255, 181, 54)", fontSize: "40px" }}>
                <a
                  href="tel:07984670782"
                  style={{ color: "rgb(255, 181, 54)", textDecoration: "none" }}
                >
                  07984 670 782
                  {/* {this.props.loadStatus? this.props.data.phone :""} */}
                </a>
              </h4>
            </div>
          </li>
        </ul>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            Brochures, Publications and E-Books
          </ModalHeader>
          <ModalBody>
            <div style={{ margin: "5%" }}>
              <Button className="form-control">
                <a
                  href="./assets/pdf/7-Stress-Relieving-Laundry-Tips-for-a-Busy-Houshold11.pdf"
                  download
                  onClick={this.toggle}
                >
                  Brochure
                  <br />
                </a>
              </Button>
            </div>
            <div style={{ margin: "5%" }}>
              <Button className="form-control">
                <a href="#" onClick={this.commingSoon}>
                  Publications
                  <br />
                </a>
              </Button>
            </div>
            <div style={{ margin: "5%" }}>
              <Button className="form-control">
                <a href="#" onClick={this.commingSoon}>
                  E-Books
                  <br />
                </a>
              </Button>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
export default Booking;
