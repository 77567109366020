import React from "react";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Booking from "../MUC/booking.jsx";
import Services from "../MUC/services.jsx"; 
import { Link } from "react-router-dom";

import GetbaseUrl from "../base_url";
class AboutUsContent extends React.Component {
 
  constructor(props) {
    var url = GetbaseUrl();
    super(props);
    this.state = {
      baseUrl: url,
    };
  }
  render()
  {
    const handleOnDragStart = e => e.preventDefault();
    return (
      <React.Fragment>
        <div id="main" className="sidebar-right sidebar-divider-off">
          <div className="main-gradient" />
          <div className="wf-wrap">
            <div className="wf-container-main">
              <div id="content" className="content" role="main">
                <div className="vc_row wpb_row vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
    <p>{this.props.isLoaded? this.props.data[0]["main_text1"]:""}</p>
                            <p>{this.props.isLoaded? this.props.data[0]["main_text2"]:""}</p>
                            <p><strong>{this.props.isLoaded? this.props.data[0]["sub_heading"]:""}</strong></p>
                            <p>{this.props.isLoaded? this.props.data[0]["sub_content1"]:""}</p>
                            <p>{this.props.isLoaded? this.props.data[0]["sub_content2"]:""}</p>
                          </div>
                        </div>
                        <div className="smile_icon_list_wrap ult_info_list_container ult-adjust-bottom-margin   vc_custom_1512727346580">
                          <ul className="smile_icon_list left hexagon">
                            <li className="icon_list_item" style={{ fontSize: '60px' }}>
                              <div className="icon_list_icon" data-animation data-animation-delay={"03"} style={{ fontSize: '20px', background: '#ffb536', color: '#ffffff', borderColor: '#ffb536' }}><i className="icomoon-icomoonfree-16x16-pen" /></div>
                              <div className="icon_description" id="Info-list-wrap-1846" style={{ fontSize: '20px' }}>
                                <div className="icon_description_text ult-responsive" data-ultimate-target="#Info-list-wrap-1846 .icon_description_text" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:13px;&quot;,&quot;line-height&quot;:&quot;desktop:18px;&quot;}" style={{}}>
                                  <p>{this.props.isLoaded? this.props.data[0]["point1"]:""}</p>
                                </div>
                              </div>
                              <div className="icon_list_connector" style={{ borderRightWidth: '1px', borderRightStyle: 'dashed', borderColor: '#ffb536' }} />
                            </li>
                            <li className="icon_list_item" style={{ fontSize: '60px' }}>
                              <div className="icon_list_icon" data-animation data-animation-delay={"03"} style={{ fontSize: '20px', background: '#ffb536', color: '#ffffff', borderColor: '#ffb536' }}><i className="icomoon-icomoonfree-16x16-bubbles4" /></div>
                              <div className="icon_description" id="Info-list-wrap-2456" style={{ fontSize: '20px' }}>
                                <div className="icon_description_text ult-responsive" data-ultimate-target="#Info-list-wrap-2456 .icon_description_text" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:13px;&quot;,&quot;line-height&quot;:&quot;desktop:18px;&quot;}" style={{}}>
                                  <p>{this.props.isLoaded? this.props.data[0]["point2"]:""}</p>
                                </div>
                              </div>
                              <div className="icon_list_connector" style={{ borderRightWidth: '1px', borderRightStyle: 'dashed', borderColor: '#ffb536' }} />
                            </li>
                            <li className="icon_list_item" style={{ fontSize: '60px' }}>
                              <div className="icon_list_icon" data-animation data-animation-delay={"03"} style={{ fontSize: '20px', background: '#ffb536', color: '#ffffff', borderColor: '#ffb536' }}><i className="icomoon-icomoonfree-16x16-stats-bars" /></div>
                              <div className="icon_description" id="Info-list-wrap-3421" style={{ fontSize: '20px' }}>
                                <div className="icon_description_text ult-responsive" data-ultimate-target="#Info-list-wrap-3421 .icon_description_text" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:13px;&quot;,&quot;line-height&quot;:&quot;desktop:18px;&quot;}" style={{}}>
                                  <p>{this.props.isLoaded? this.props.data[0]["point3"]:""}</p>
                                </div>
                              </div>
                              <div className="icon_list_connector" style={{ borderRightWidth: '1px', borderRightStyle: 'dashed', borderColor: '#ffb536' }} />
                            </li>
                            <li className="icon_list_item" style={{ fontSize: '60px' }}>
                              <div className="icon_list_icon" data-animation data-animation-delay={"03"} style={{ fontSize: '20px', background: '#ffb536', color: '#ffffff', borderColor: '#ffb536' }}><i className="icomoon-icomoonfree-16x16-cogs" /></div>
                              <div className="icon_description" id="Info-list-wrap-8109" style={{ fontSize: '20px' }}>
                                <div className="icon_description_text ult-responsive" data-ultimate-target="#Info-list-wrap-8109 .icon_description_text" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:13px;&quot;,&quot;line-height&quot;:&quot;desktop:18px;&quot;}" style={{}}>
                                  <p>{this.props.isLoaded? this.props.data[0]["point4"]:""}</p>
                                </div>
                              </div>
                              <div className="icon_list_connector" style={{ borderRightWidth: '1px', borderRightStyle: 'dashed', borderColor: '#ffb536' }} />
                            </li>
                            <li className="icon_list_item" style={{ fontSize: '60px' }}>
                              <div className="icon_list_icon" data-animation data-animation-delay={"03"} style={{ fontSize: '20px', background: '#ffb536', color: '#ffffff', borderColor: '#ffb536' }}><i className="Defaults-star" /></div>
                              <div className="icon_description" id="Info-list-wrap-3814" style={{ fontSize: '20px' }}>
                                <div className="icon_description_text ult-responsive" data-ultimate-target="#Info-list-wrap-3814 .icon_description_text" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:13px;&quot;,&quot;line-height&quot;:&quot;desktop:18px;&quot;}" style={{}}>
                                  <p>{this.props.isLoaded? this.props.data[0]["point5"]:""}</p>
                                </div>
                              </div>
                              <div className="icon_list_connector" style={{ borderRightWidth: '1px', borderRightStyle: 'dashed', borderColor: '#ffb536' }} />
                            </li>
                          </ul>
                        </div>
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <p><strong>Call Now {this.props.isLoaded? this.props.data[0]["Phone_No"]:""}</strong></p>
                            <p>{this.props.isLoaded? this.props.data[0]["News"]:""}<br /> {this.props.isLoaded? this.props.data[0]["Frist_content_Under_News"]:""}</p>
                            <p>{this.props.isLoaded? this.props.data[0]["2nd_content_Under_news"]:""}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div id="ultimate-heading-48815d3a1aa2608ac" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-48815d3a1aa2608ac uvc-279 " data-hspacer="no_spacer" data-halign="center" style={{ textAlign: 'center' }}>
                          <div className="uvc-heading-spacer no_spacer" style={{}} />
                          <div className="uvc-main-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-48815d3a1aa2608ac h2" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}">
                            <h2 style={{ fontWeight: 'normal' }}>We Cover All Makes and Models, Including these Manufacturers:</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <AliceCarousel mouseDragEnabled buttonsDisabled={true} autoPlayInterval={2000}
                          autoPlayDirection="ltl"
                          autoPlay={true}
                          fadeOutAnimation={true}
                          responsive={{
                            0: { items: 1 }, 1024: {
                              items: 4
                            }
                          }} >
                          {this.props.isLoaded?
                            this.props.datalogo.map((item, index) => { 
                              return (
                                <img
                                 src={this.state.baseUrl + "asset/img/sliderLogo/" + item.logo_pic} 
                                 alt="Manufacturers" width={200} height={70} onDragStart={handleOnDragStart} className="yours-custom-class" alt="First Serve UK" />
                              )
                            })
                          :""}
  
                        </AliceCarousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1512978749856">
                      <div className="wpb_wrapper">
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <p><a href="https://www.aeg.co.uk/" target="_blank" rel="noopener noreferrer">AEG</a> –<a href="http://www.ariston.com/uk/" target="_blank" rel="noopener noreferrer"> Ariston</a> – <a href="http://www.baumatic.co.uk/" target="_blank" rel="noopener noreferrer">Baumatic</a> – <a href="https://www.beko.co.uk/" target="_blank" rel="noopener noreferrer">Beko</a> – <a href="https://www.belling.co.uk/" target="_blank" rel="noopener noreferrer">Belling</a> – <a href="https://www.whitegoodshelp.co.uk/washing-machine-reviews/" target="_blank" rel="noopener noreferrer">Bendix</a> – <a href="https://www.bosch.co.uk/" target="_blank" rel="noopener noreferrer">Bosch</a> – <a href="http://www.ciao.co.uk/search?query=washing%20machines" target="_blank" rel="noopener noreferrer">Brandt</a> – <a href="https://www.which.co.uk/reviews/washing-machines/bush/brand" target="_blank" rel="noopener noreferrer">Bush</a> – <a href="http://www.candy-domestic.co.uk/en_GB/appliances/wash-dry" target="_blank" rel="noopener noreferrer">Candy</a> – <a href="http://www.cannoncooking.co.uk/products.shtml" target="_blank" rel="noopener noreferrer">Cannon</a> – <a href="http://www.cda.eu/" target="_blank" rel="noopener noreferrer">CDA</a> – <a href="https://www.hotpointservice.co.uk/" target="_blank" rel="noopener noreferrer">Creda</a> –
                            {/* <a href="https://awsappliancespares.com/" target="_blank" rel="noopener noreferrer">Diplomat</a> */}
                              <a href="http://www.ciao.co.uk/search?query=washing%20machines" target="_blank" rel="noopener noreferrer">Electra</a> – <a href="https://www.electrolux.co.uk/care/" target="_blank" rel="noopener noreferrer">Electrolux</a> – <a href="http://www.ciao.co.uk/search?query=washing%20machines" target="_blank" rel="noopener noreferrer">Haier</a> – <a href="https://www.hoover.co.uk/" target="_blank" rel="noopener noreferrer">Hoover</a> – <a href="https://www.hotpoint.co.uk/">Hotpoint</a> – <a href="http://www.ciao.co.uk/search?query=washing%20machines" target="_blank" rel="noopener noreferrer">Ignis</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-3">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper" />
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="vc_btn3-container  appointment vc_btn3-center"> <a className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-round vc_btn3-style-flat vc_btn3-block vc_btn3-color-primary" href="http://p24.421.myftpupload.com/contact/" >Book an Appointment Now</a></div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-3">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper" />
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <div id={1} className="widgets_on_page wop_tiny1  wop_small1  wop_medium1  wop_large1  wop_wide1">
                              <ul>
                                <li id="text-7" className="widget widget_text">
                                  <div className="textwidget">
                                    <ul>
                                      <li><a className="cta-button" href="http://firstserveuk.simplybook.it/v2/#book" target="_blank" rel="noopener noreferrer">Same Day Service</a></li>
                                      <li><a className="cta-button cta-button-2" href="http://firstserveuk.simplybook.it/v2/#book" target="_blank" rel="noopener noreferrer">Next Day Service</a></li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside id="sidebar" className="sidebar bg-under-widget">
                <div className="sidebar-content widget-divider-off">
                  <section id="text-9" className="widget widget_text" style={{
                    background: "#014485",
                    borderRadius: "5px"
                  }}>
                    <div className="textwidget" style={{ marginBottom: "10px" }}>
                      <div className="sidebar-cta">
                <h2 style={{ color: "#ffffff" }}><strong style={{ color: "#ffffff", fontWeight: "700" }}>{this.props.isLoaded? this.props.data[0]["right_side_main_content"]:""}</strong> {this.props.isLoaded? this.props.data[0]["right_side_main_content1"]:""} <a href="http://firstserveuk.simplybook.it/v2/" target="_blank" style={{ color: '#fff', textDecoration: 'underline' }} rel="noopener noreferrer">{this.props.isLoaded? this.props.data[0]["right_side_link_on_form"]:""}</a></h2>
                        <ul style={{ color: "#ffffff" }}>
                <li>{this.props.isLoaded? this.props.data[0]["right_side_sub_content"]:""}</li>
                          <li>{this.props.isLoaded? this.props.data[0]["right_side_sub_content1"]:""}</li>
                          <li>{this.props.isLoaded? this.props.data[0]["Guarantee"]:""}</li>
                        </ul>
                        <p><a style={{
                          backgroundColor: "#ffb536", padding: "20px", margin: "0", display: "block", color: "#000000",
                          backgroundImage: "linear-gradient(to bottom,rgba(255,255,255,.2) 0,rgba(255,255,255,.01) 100%)",
                          backgroundRepeat: "repeat-x", textDecoration: "none", borderRadius: "7px", maxWidth: "360px",
                          lineHeight: "1.2", textAlign: "center", cursor: "pointer",
                          font: "normal 15px/23px `Arial`,Helvetica,Arial,Verdana,sans-serif",
                          wordSpacing: "normal"
                        }} href="http://firstserveuk.simplybook.it/v2/" target="_blank" rel="noopener noreferrer"><strong style={{ color: "#000000", fontSize: "26px" }}>{this.props.isLoaded? this.props.data[0]["right_side_phone_no"]:""}</strong> <br /><span style={{ textDecoration: 'underline', fontSize: "20px" }}>24-Hour Online Booking</span></a></p>
                      </div>
                    </div>
                  </section>
                  <section id="presscore-custom-menu-1-2" className="widget widget_presscore-custom-menu-1">
                    <div className="widget-title">Services</div>
                    <Services />
                  </section>
                  <section id="text-3" className="widget widget_text">
                    <div className="textwidget">
                      <h4 style={{ color: '#fff', background: '#013c7a' }}>Contact Us</h4>
                      <div role="form" className="wpcf7" id="wpcf7-f4-o1" lang="en-US" dir="ltr">
                        <div className="screen-reader-response" />
                        <form action="https://firstserveuk.co.uk/about-us/#wpcf7-f4-o1" method="post" className="wpcf7-form" noValidate="novalidate">
                          <div style={{ display: 'none' }}>
                            <input type="hidden" name="_wpcf7" defaultValue={4} />
                            <input type="hidden" name="_wpcf7_version" defaultValue="5.1.1" />
                            <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" />
                            <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f4-o1" />
                            <input type="hidden" name="_wpcf7_container_post" defaultValue={0} />
                            <input type="hidden" name="g-recaptcha-response" defaultValue />
                          </div>
                          <p>
                            <span className="wpcf7-form-control-wrap your-name">
                              <input type="text" name="your-name" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Full Name" />
                            </span>
                            <br />
                            <span className="wpcf7-form-control-wrap your-email">
                              <input type="text" name="your-email" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Email Address" />
                            </span>
                            <br />
                            <span className="wpcf7-form-control-wrap your-Telephone">
                              <input type="text" name="your-Telephone" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Telephone Number" />
                            </span>
                            <br />
                            <span className="wpcf7-form-control-wrap makeModelofAppliance">
                              <input type="text" name="makeModelofAppliance" size={40} className="wpcf7-form-control wpcf7-text" placeholder="Make Model of Appliance" />
                            </span>
                            <br />
                            <span className="wpcf7-form-control-wrap NatureofFault">
                              <input type="text" name="NatureofFault" size={40} className="wpcf7-form-control wpcf7-text" placeholder="Nature of Fault" />
                            </span>
                            <br />
                            <span className="wpcf7-form-control-wrap Comment">
                              <textarea name="Comment" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" placeholder="Comment..." />
                            </span>
                          </p>
                          <p><input type="submit" defaultValue="SUBMIT" className="wpcf7-form-control wpcf7-submit" /></p>
                          <div className="wpcf7-response-output wpcf7-display-none" />
                        </form>
                      </div>
                    </div>
                  </section>
                </div>
              </aside>
            </div>
          </div>
          <Booking />
        </div>
      </React.Fragment>
    );
  }
};
export default AboutUsContent;
