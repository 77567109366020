import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import TumblerContent from "./TumblerContent";

class Tumbler extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tumbler and Condenser Dryers in London | First Serve UK</title>
          <meta
            name="description"
            content="Tumbler and Condenser Dryers in London. We provide Same/Next Day Service, 24-
            Hour Online Booking or Call 07984 670 782 for"
          />
          <link
            rel="canonical"
            href="https://firstserveuk.co.uk/tumbler-and-condenser-dryers"
          />
        </Helmet>
        <div className="page-title title-left solid-bg page-title-responsive-enabled">
          <div className="wf-wrap">
            <div className="page-title-head hgroup">
              <h1>Tumbler and condenser dryers</h1>
            </div>
            <div className="page-title-breadcrumbs">
              <div className="assistive-text">You are here:</div>
              <ol className="breadcrumbs text-small">
                <li typeof="v:Breadcrumb">
                  <Link to="/">Home</Link>
                </li>
                <li typeof="v:Breadcrumb">
                  <Link to="/services">Services</Link>
                </li>
                <li className="current">Tumbler and condenser dryers</li>
              </ol>
            </div>
          </div>
        </div>
        <TumblerContent />
        {/* <MainJS /> */}
        {/* <Script url="./assets/js/jquery.js" />
        <Script url="./assets/js/main.js" /> */}
      </React.Fragment>
    );
  }
}

export default Tumbler;
