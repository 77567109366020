import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReadMoreContent from "./ReadMoreContent";

class ReadMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsDetail: this.props.location.state
        ? this.props.location.state.newsList
        : []
    };
  }
  render() {
    const { newsDetail } = this.state;
    // console.log(newsDetail);
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Washing machine repair London - First Serve UK</title>
          <meta
            name="description"
            content="Washing Machine Repairs London - First Serve UK. Same/Next Day Service, 24-Hour Online Booking, Long Guarantee and Special Offers"
          />
        </Helmet>
        <div className="page-title title-left solid-bg page-title-responsive-enabled">
          <div className="wf-wrap">
            <div className="page-title-head hgroup">
              <h1>{newsDetail.name}</h1>
            </div>
            <div className="page-title-breadcrumbs">
              <div className="assistive-text">You are here:</div>
              <ol className="breadcrumbs text-small">
                <li typeof="v:Breadcrumb">
                  <Link to="/">Home</Link>
                </li>
                <li className="current">{newsDetail.name}</li>
              </ol>
            </div>
          </div>
        </div>
        <ReadMoreContent newsDetail={newsDetail} />
      </React.Fragment>
    );
  }
}

export default ReadMore;
