import React from "react";
import { Component } from "react";
import $ from "jquery";
class ContactUsContent extends Component {
   constructor(props) {
      super(props);
    }
  render()
  {
   return (
      <React.Fragment>
        <div
          id="main"
          className="sidebar-none sidebar-divider-off"
          style={{ paddingTop: "70px", paddingBottom: "0px" }}
        >
          <div className="main-gradient" />
          <div className="wf-wrap">
            <div className="wf-container-main">
              <div id="content" className="content" role="main">
              <div className="vc_row wpb_row vc_row-fluid vc_custom_1498572765023">
     <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-7 vc_col-xs-12">
        <div className="vc_column-inner vc_custom_1498821716836">
           <div className="wpb_wrapper">
              <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1498572805259 vc_row-o-equal-height vc_row-flex">
                 <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1475919323336">
                       <div className="wpb_wrapper">
                          <div className="aio-icon-component  vc_custom_1512977110396  accent-icon-bg color-title style_1">
                             <div id="Info-box-wrap-7220" className="aio-icon-box left-icon" style={{}}>
                                
                                <div className="aio-ibd-block"><div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-7220 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:18px;&quot;,&quot;line-height&quot;:&quot;desktop:24px;&quot;}" style={{ fontFamily: '"Open Sans Condensed"', fontWeight: 700 }}> 
                                                       <div className="aio-icon advanced " style={{ color: '#ffffff', borderStyle: '', borderColor: '#333333', borderWidth: '1px', width: '34px', height: '34px', lineHeight: '34px', borderRadius: '500px', fontSize: '16px', display: 'inline-block' }}> <i style={{color:"#ffffff"}} className="icomoon-icomoonfree-16x16-office" /></div>
                                &nbsp;{this.props.parentStates.data.address}</div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
                                     <div className="wpb_column vc_column_container vc_col-sm-12" style={{ marginTop: "20px" }}>
           <div className="vc_column-inner vc_custom_1475919331923">
              <div className="wpb_wrapper">
                 <div className="aio-icon-component  vc_custom_1512977223688  accent-icon-bg color-title style_1">
                    <div id="Info-box-wrap-1304" className="aio-icon-box left-icon" style={{}}>
                      
                    <div className="aio-ibd-block"><div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-1304 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:18px;&quot;,&quot;line-height&quot;:&quot;desktop:24px;&quot;}" style={{ fontFamily: '"Open Sans Condensed"', fontWeight: 700 }}><div className="aio-icon advanced " style={{ color: '#ffffff', borderStyle: '', borderColor: '#333333', borderWidth: '1px', width: '34px', height: '34px', lineHeight: '34px', borderRadius: '500px', fontSize: '16px', display: 'inline-block' }}> <i style={{ color: "#ffffff" }} className="icomoon-icomoonfree-16x16-stopwatch" />
                    </div> &nbsp; {this.props.parentStates.data.timing}</div>
              </div>
           </div>
        </div>
     </div>
  </div>
  </div>
  <div className="wpb_column vc_column_container vc_col-sm-12" style={{marginTop:"20px"}}>
     <div className="vc_column-inner vc_custom_1475919340203">
        <div className="wpb_wrapper">
           <div className="aio-icon-component  vc_custom_1529242120433  accent-icon-bg color-title style_1">
              <a className="aio-icon-box-link" href="tel:07984 670782">
                 <div id="Info-box-wrap-9157" className="aio-icon-box left-icon" style={{}}>
  
        <div className="aio-ibd-block"><div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-9157 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:18px;&quot;,&quot;line-height&quot;:&quot;desktop:24px;&quot;}" style={{ fontFamily: '"Open Sans Condensed"', fontWeight: 700 }}> <div className="aio-icon advanced " style={{ color: '#ffffff', borderStyle: '', borderColor: '#333333', borderWidth: '1px', width: '34px', height: '34px', lineHeight: '34px', borderRadius: '500px', fontSize: '16px', display: 'inline-block' }}> <i style={{ color: "#ffffff" }} className="icomoon-icomoonfree-16x16-phone" />
   </div> &nbsp; {this.props.parentStates.data.phone}</div>
        </div>
     </div>
     </a>
  </div>
  </div></div></div></div>
  
                                  <div className="wpb_single_image wpb_content_element vc_align_center">
     <figure className="wpb_wrapper vc_figure"> <a href="https://www.google.co.uk/maps/place/Sherwood+Ave,+London+SW16+5EE,+UK/@51.4140353,-0.1362256,17z/data=!3m1!4b1!4m5!3m4!1s0x4876068c0ca8fc59:0x5c6597199559be79!8m2!3d51.4140353!4d-0.1340369" target="_blank" className="vc_single_image-wrapper   vc_box_border_grey rollover this-ready" data-large_image_width={1024} data-large_image_height={998}><img width={1124} height={1096} src="https://firstserveuk.co.uk/wp-content/uploads/2018/06/map.jpg" className="vc_single_image-img attachment-full" alt="First Serve UK address map view" srcSet="https://firstserveuk.co.uk/wp-content/uploads/2018/06/map.jpg 1124w, https://firstserveuk.co.uk/wp-content/uploads/2018/06/map-300x293.jpg 300w, https://firstserveuk.co.uk/wp-content/uploads/2018/06/map-768x749.jpg 768w, https://firstserveuk.co.uk/wp-content/uploads/2018/06/map-1024x998.jpg 1024w, https://firstserveuk.co.uk/wp-content/uploads/2018/06/map-600x585.jpg 600w" sizes="(max-width: 1124px) 100vw, 1124px" data-dt-location="https://firstserveuk.co.uk/contact-first-serve-uk/map/" /><i /></a></figure>
  </div>
  </div></div></div>
  
                         <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-5 vc_col-xs-12">
     <div className="vc_column-inner vc_custom_1498572768127">
        <div className="wpb_wrapper">
           <div className="wpb_text_column wpb_content_element  vc_custom_1512973989217">
              <div className="wpb_wrapper" id="contentOfContact">
             {/* here */}
             {this.props.parentStates.isLoaded?this.uploadContent():this.loadeing()}
              </div>
           </div>
           <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                 <div role="form" className="wpcf7" id="wpcf7-f5-p13-o1" lang="en-US" dir="ltr">
                    <div className="screen-reader-response" />
                       <form action="/contact-first-serve-uk/#wpcf7-f5-p13-o1" method="post" className="wpcf7-form" noValidate="novalidate">
                          <div style={{display: 'none'}}> <input type="hidden" name="_wpcf7" value="5" /> <input type="hidden" name="_wpcf7_version" value="5.1.1" /> <input type="hidden" name="_wpcf7_locale" value="en_US" /> <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f5-p13-o1" /> <input type="hidden" name="_wpcf7_container_post" value="13" /> <input type="hidden" name="g-recaptcha-response" />
                    </div>
                    <p><label> Your Name (required)<br /> <span className="wpcf7-form-control-wrap your-name"><input type="text" name="your-name" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" /></span> </label></p><p><label> Your Email (required)<br /> <span className="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" /></span> </label></p><p><label> Your Telephone Number (required)<br /> <span className="wpcf7-form-control-wrap your-number"><input type="tel" name="your-number" size={40} className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel" aria-required="true" aria-invalid="false" /></span> </label></p><p><label> Subject<br /> <span className="wpcf7-form-control-wrap your-subject"><input type="text" name="your-subject" size={40} className="wpcf7-form-control wpcf7-text" aria-invalid="false" /></span> </label></p><p><label> Your Message<br /> <span className="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false"  /></span> </label></p><p><input type="submit" defaultValue="Send" className="wpcf7-form-control wpcf7-submit" /><span className="ajax-loader" /></p><div className="wpcf7-response-output wpcf7-display-none" /></form></div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
uploadContent =()=>{
   $("#contentOfContact").html(this.props.parentStates.data.content);
}
loadeing =()=>{
   $("#contentOfContact").html("loadeing ... ");
}

};
export default ContactUsContent;
