import React from "react";
// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const MainJS = () => {
  return (
    <React.Fragment>
      {/* Helmet Tag */}
      <Helmet>
        {/* inline script elements */}
        <script src="./assets/js/jquery.js" />
        <script type="text/javascript" defer src="./assets/js/main.js" />
      </Helmet>
      {/* #END# Helmet Tag */}
    </React.Fragment>
  );
};

export default MainJS;
