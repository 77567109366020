import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Services extends Component {
    render() {
        return (
            <React.Fragment>
                <ul className="custom-menu">
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2628 first"><a href="https://www.google.com/maps/place/First+Serve+UK+Washing+Machine+Repairs/@51.4868865,-0.2129854,11z/data=!3m1!4b1!4m7!3m6!1s0x0:0xa6f9709d40c985f2!8m2!3d51.4869699!4d-0.072903!9m1!1b1" target="_blank" rel="noopener noreferrer">Latest Reviews</a></li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2667"><a href="http://firstserveuk.simplybook.it/v2/" target="_blank" rel="noopener noreferrer">Book One, Get One FREE</a></li>
                    <li className="menu-item  menu-item-object-page menu-item-2604" >
                        <Link to="washing-machine-repairs" data-level={2}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs</span></span></Link>
                    </li>
                    <li className="menu-item  menu-item-object-page menu-item-2604" >
                        <Link to="washing-machine-repair-london" data-level={2}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs London</span></span></Link>
                    </li>
                    <li className="menu-item  menu-item-object-page menu-item-2604" >
                        <Link to="washing-machine-repair-kensington" data-level={2}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs Kensington</span></span></Link>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2605" >
                        <Link to="tumbler-and-condenser-dryers" data-level={2}>
                            <span className="menu-item-text"><span className="menu-text">Tumbler and Condenser Dryers</span></span></Link>
                    </li>
                    {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2622"><a to="https://www.espares.co.uk/">Washing Machine Spares</a></li> */}
                    {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2624"><Link to="/electric-cooker-and-oven-repa">Cookers</Link></li> */}
                    {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2624"><a href="#">Fridge repairs</a></li> */}
                    {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2625"><a to="../first-serve-uk/blocked-sinks-and-drains/index.html">Sink Unblocking</a></li> */}
                    {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2627"><Link to="/dishwashers">Dishwashers</Link></li> */}
                    {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2626"><a to="../central-heating/index.html">Central Heating</a></li> */}
                </ul>
            </React.Fragment>
        );
    }
}
export default Services;
