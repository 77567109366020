import React from "react";
import { Link } from "react-router-dom";
export default class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }

  }
  render() {
    const { dataUsp1, dataUsp2, isLoaded } = this.props;
    return (

      <React.Fragment>
        <div className="services-menu-sidebar-widget cs-col-2 wpb_column vc_column_container vc_col-sm-3">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div className="wpb_widgetised_column wpb_content_element sidebar-content solid-bg">
                <div className="wpb_wrapper">
                  <section
                    id="presscore-custom-menu-1-4"
                    className="widget widget_presscore-custom-menu-1">
                    <div className="widget-title">We save you </div>
                    <ul className="custom-menu">
                      {isLoaded ? dataUsp1.map((item, index) => {
                        return (<li key={index} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2628 first">
                          <a>{item.title}</a>
                        </li>)
                      }) : <div>"Loading..."</div>}
                    </ul>
                  </section>
                  <section
                    id="presscore-custom-menu-1-4"
                    className="widget widget_presscore-custom-menu-1"
                  >
                    <div className="widget-title">Services</div>
                    <ul className="custom-menu">
                      {isLoaded ?
                        dataUsp2.map((item, index) => {
                          return (<li key={index} className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2628 first">
                            <a href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"

                            >{item.title}</a>
                          </li>)
                        })
                        : <div>"Loading..."</div>}


                      {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2622">
                        <a href="https://www.espares.co.uk/" target="_blank"
                          rel="noopener noreferrer">
                          Washing Machine Spares
                        </a>
                      </li> */}
                      {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2624">
                        <a>
                          Fridge repairs
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2624">
                        <Link to="/electric-cooker-and-oven-repa">
                          Cookers
                        </Link>
                      </li> */}
                      {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2625">
                        <a href="first-serve-uk/blocked-sinks-and-drains/index.html">
                          Sink Unblocking
                        </a>
                      </li> */}
                      {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2627">
                        <Link to="/dishwashers">Dishwashers</Link>
                      </li> */}
                      {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2626">
                        <a href="central-heating/index.html">Central Heating</a>
                      </li> */}
                    </ul>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

};

