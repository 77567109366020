import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import DishwashersContent from "./DishwashersContent";

class Dishwashers extends Component {

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Washing machine repair London - First Serve UK</title>
          <meta
            name="description"
            content="Washing Machine Repairs London - First Serve UK. Same/Next Day Service, 24-Hour Online Booking, Long Guarantee and Special Offers"
          />
        </Helmet>
        <div className="page-title title-left solid-bg page-title-responsive-enabled">
          <div className="wf-wrap">
            <div className="page-title-head hgroup">
              <h1>Dishwashers</h1>
            </div>
            <div className="page-title-breadcrumbs">
              <div className="assistive-text">You are here:</div>
              <ol className="breadcrumbs text-small">
                <li typeof="v:Breadcrumb">
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Dishwashers</li>
              </ol>
            </div>
          </div>
        </div>
        <DishwashersContent />
        {/* <MainJS /> */}
        {/* <Script url="./assets/js/jquery.js" />
        <Script url="./assets/js/main.js" /> */}
      </React.Fragment>
    );
  }
}

export default Dishwashers;
