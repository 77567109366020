import React from "react";

const News = () => {
  return (
    <React.Fragment>
      <div className="vc_column-inner ">
        <div className="wpb_wrapper">
          <div
            id="ultimate-heading-48525d39c86d8c616"
            className="uvc-heading ult-adjust-bottom-margin ultimate-heading-48525d39c86d8c616 uvc-4956 txt_post cs-post-title"
            data-hspacer="no_spacer"
            data-halign="left"
            style={{ textAlign: "left" }}
          >
            <div className="uvc-heading-spacer no_spacer" style={{}} />
            <div
              className="uvc-main-heading ult-responsive"
              data-ultimate-target=".uvc-heading.ultimate-heading-48525d39c86d8c616 h2"
              data-responsive-json-new='{"font-size":"desktop:28px;","line-height":""}'
            >
              <h2 style={{ fontWeight: "bold", color: "#027cb6" }}>News</h2>
            </div>
          </div>
          <div
            className="articles-list blog-shortcode mode-list blog-list-shortcode-id-1 classic-layout-list content-bg-on jquery-filter scale-img loading-effect-fade-in"
            data-cur-page={1}
            data-post-limit={-1}
            data-pagination-mode="none"
          >
            <article
              className="post project-odd visible no-img post-2885 type-post status-publish format-standard hentry category-news category-4 description-off"
              data-name="LINT IN YOUR WASHING MACHINE?"
              data-date="2019-02-05T07:55:17+01:00"
            >
              <div className="post-entry-content">
                <h3 className="entry-title">
                  {" "}
                  <a
                    href="lint-in-your-washing-machine/index.html"
                    title="LINT IN YOUR WASHING MACHINE?"
                    rel="bookmark"
                  >
                    LINT IN YOUR WASHING MACHINE?
                  </a>
                </h3>
                <div className="entry-meta">
                  <a
                    href="2019/02/05/index.html"
                    title="7:55 am"
                    className="data-link"
                    rel="bookmark"
                  >
                    <time
                      className="entry-date updated"
                      dateTime="2019-02-05T07:55:17+01:00"
                    >
                      February 5, 2019
                    </time>
                  </a>
                </div>
                <div className="entry-excerpt">
                  <p>
                    The story behind the lint and the buttons, comes from a very
                    polite lady who checked the filter and pump, but couldn’t
                    find the blockage in the drain hose.
                  </p>
                </div>
              </div>
            </article>
            <article
              className="post project-odd visible no-img post-2855 type-post status-publish format-standard hentry category-news category-uncategorized category-4 category-1 description-off"
              data-name="THE BEST GUIDE ON LG WASHING MACHINE REPAIR"
              data-date="2018-12-30T20:28:30+01:00"
            >
              <div className="post-entry-content">
                <h3 className="entry-title">
                  {" "}
                  <a
                    href="the-best-guide-on-lg-washing-machine-repair/index.html"
                    title="THE BEST GUIDE ON LG WASHING MACHINE REPAIR"
                    rel="bookmark"
                  >
                    THE BEST GUIDE ON LG WASHING MACHINE REPAIR
                  </a>
                </h3>
                <div className="entry-meta">
                  <a
                    href="2018/12/30/index.html"
                    title="8:28 pm"
                    className="data-link"
                    rel="bookmark"
                  >
                    <time
                      className="entry-date updated"
                      dateTime="2018-12-30T20:28:30+01:00"
                    >
                      December 30, 2018
                    </time>
                  </a>
                </div>
                <div className="entry-excerpt">
                  <p>
                    There are a lot of manufacturers of washing machine around
                    the world today. However, one of them that definitely stands
                    out from the rest are washing machines which are produced by
                    LG. while this machine is durable and has few issues, there
                    are still repairs that may need to be done on it from time…
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default News;
