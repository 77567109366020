import React from "react";
import Booking from "../MUC/booking.jsx";
import { Link } from "react-router-dom";

const ServicesContent = () => {
  return (
    <React.Fragment>
      <div
        id="main"
        className="sidebar-none sidebar-divider-off"
        style={{ paddingTop: "70px", paddingBottom: "0px" }}
      >
        <div className="main-gradient" />
        <div className="wf-wrap">
          <div className="wf-container-main">
            <div id="content" className="content" role="main">
              <div className="vc_row wpb_row vc_row-fluid vc_custom_1498212582492">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>All washing machine repairs are undertaken by qualified and professional engineers.</p>
                  <p>Electrical diagnostics, fault finding, washing machine function testing, are carried out on all repairs.</p>
                  <p>We repair/replace wiring, change on/off switches, replace electric filters, fit door interlocks, install programme/timer units, test and fit water valves, test and fit pressure switches, test wash heaters and thermostats, as well as fit them. We also unblock pumps, fit and/or repair them.</p>
                  <p>Did you know there are 6 main reasons that can cause a washing machine not to drain? Our Engineers know where they are and how to fix them. We repair and/or fit motors, as well as fit motor brushes.</p>
                  <p>We also fit belts, and pulley wheels known as “spiders”. We also repair broken washing machine door handles, washing machine doors.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-4">
          <div className="vc_column-inner vc_custom_1498211177630">
            <div className="wpb_wrapper">
              <div className="shortcode-single-image-wrap alignnone vc_custom_1520619978769"><div className="shortcode-single-image"><div className="fancy-media-wrap" style={{borderRadius: '500px'}}><Link to="/washing-machine-repairs" className="rollover this-ready layzr-bg-transparent" style={{borderRadius: '500px'}}><img className="lazy-load preload-me is-loaded" src="https://firstserveuk.co.uk/wp-content/uploads/2017/12/Washing-Machine-Repairs-1-220x220.png" width={220} height={220} data-dt-location="https://firstserveuk.co.uk/washing-machine-repairs-image/" alt="First Serve UK" srcSet="https://firstserveuk.co.uk/wp-content/uploads/2017/12/Washing-Machine-Repairs-1-220x220.png 220w, https://firstserveuk.co.uk/wp-content/uploads/2017/12/Washing-Machine-Repairs-1-246x246.png 246w" style={{willChange: 'auto'}} /><i /></Link></div></div></div>

              <span id="creative-link-wrap-3291" className="ult_main_cl ult-adjust-bottom-margin  "> <span className="  ult_crlink"> <Link data-ultimate-target="#creative-link-wrap-3291 .ult_colorlink" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:26px;&quot;,&quot;line-height&quot;:&quot;desktop:32px;&quot;}" to="/washing-machine-repairs" className="ult_colorlink ult-responsive  vc_custom_1520620022384" style={{fontFamily: 'Open Sans Condensed', fontWeight: 700}} data-textcolor="#1f365c" data-texthover="#ffb536" data-style> <span data-hover="Washing Machine Repairs" style={{color: '#1f365c'}} className="ult_btn10_span   ">Washing Machine Repairs</span> </Link> </span> </span>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-4">
          <div className="vc_column-inner vc_custom_1498211173058">
            <div className="wpb_wrapper">
               <div className="shortcode-single-image-wrap alignnone vc_custom_1512969245166"><div className="shortcode-single-image"><div className="fancy-media-wrap" style={{borderRadius: '500px'}}><Link to="/tumbler-and-condenser-dryers" className="rollover this-ready layzr-bg-transparent" style={{borderRadius: '500px'}}><img className="lazy-load preload-me is-loaded" src="https://firstserveuk.co.uk/wp-content/uploads/2017/12/Tumble-Dryer-Repairs-1-220x220.png" width={220} height={220} data-dt-location="https://firstserveuk.co.uk/tumble-dryer-repairs-2/" alt="First Serve UK" srcSet="https://firstserveuk.co.uk/wp-content/uploads/2017/12/Tumble-Dryer-Repairs-1-220x220.png 220w, https://firstserveuk.co.uk/wp-content/uploads/2017/12/Tumble-Dryer-Repairs-1-308x308.png 308w" style={{willChange: 'auto'}} /><i /></Link></div></div></div>
              <span id="creative-link-wrap-9378" className="ult_main_cl ult-adjust-bottom-margin  "> <span className="  ult_crlink"> <Link data-ultimate-target="#creative-link-wrap-9378 .ult_colorlink" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:26px;&quot;,&quot;line-height&quot;:&quot;desktop:32px;&quot;}" to="/tumbler-and-condenser-dryers" className="ult_colorlink ult-responsive  vc_custom_1543747504760" style={{fontFamily: 'Open Sans Condensed', fontWeight: 700}} data-textcolor="#1f365c" data-texthover="#ffb536" data-style> <span data-hover="Tumbler and Condenser Dryers" style={{color: '#1f365c'}} className="ult_btn10_span   ">Tumbler and Condenser Dryers</span> </Link> </span> </span>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-4">
          <div className="vc_column-inner vc_custom_1498211168626">
            <div className="wpb_wrapper">
              <div className="shortcode-single-image-wrap alignnone vc_custom_1512969977306"><div className="shortcode-single-image"><div className="fancy-media-wrap" style={{borderRadius: '500px'}}><Link to="/tumbler-and-condenser-dryers" className="rollover this-ready" style={{borderRadius: '500px'}}><img className="lazy-load preload-me is-loaded" src="https://firstserveuk.co.uk/wp-content/uploads/2017/05/noimage-2-1-220x220.jpg" width={220} height={220} data-dt-location="https://firstserveuk.co.uk/co-sl-002/" alt="First Serve UK" srcSet="https://firstserveuk.co.uk/wp-content/uploads/2017/05/noimage-2-1-220x220.jpg 220w, https://firstserveuk.co.uk/wp-content/uploads/2017/05/noimage-2-1-440x440.jpg 440w" style={{willChange: 'auto'}} /><i /></Link></div></div></div>
              <span id="creative-link-wrap-2733" className="ult_main_cl ult-adjust-bottom-margin  "> <span className="  ult_crlink"> <Link data-ultimate-target="#creative-link-wrap-2733 .ult_colorlink" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:26px;&quot;,&quot;line-height&quot;:&quot;desktop:32px;&quot;}" to="/tumbler-and-condenser-dryers" target="_blank" rel="noopener" className="ult_colorlink ult-responsive  vc_custom_1543747530433" style={{fontFamily: 'Open Sans Condensed', fontWeight: 700}} data-textcolor="#1f365c" data-texthover="#ffb536" data-style> <span data-hover="Washer Dryer Repairs" style={{color: '#1f365c'}} className="ult_btn10_span   ">Washer Dryer Repairs</span> </Link> </span> </span>
            </div>
          </div>
        </div>
        {/* <div className="wpb_column vc_column_container vc_col-sm-4">
          <div className="vc_column-inner vc_custom_1498211153060">
            <div className="wpb_wrapper">
              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512973462517">
                <div className="shortcode-single-image">
                  <div className="fancy-media-wrap" style={{borderRadius: '500px'}}><a href="../first-serve-uk/e-store/index.html" className="layzr-bg rollover" style={{borderRadius: '500px'}}><img className="lazy-load preload-me" src="data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20220%20220'%2F%3E" data-src="https://firstserveuk.co.uk/wp-content/uploads/2016/01/noimage-6-1-220x220.jpg" data-srcset="https://firstserveuk.co.uk/wp-content/uploads/2016/01/noimage-6-1-220x220.jpg 220w, https://firstserveuk.co.uk/wp-content/uploads/2016/01/noimage-6-1-440x440.jpg 440w" width={220} height={220} data-dt-location="https://firstserveuk.co.uk/co-006/" alt="First Serve UK" /></a></div>
                </div>
              </div>
              <span id="creative-link-wrap-4497" className="ult_main_cl ult-adjust-bottom-margin  "> <span className="  ult_crlink"> <a data-ultimate-target="#creative-link-wrap-4497 .ult_colorlink" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:26px;&quot;,&quot;line-height&quot;:&quot;desktop:32px;&quot;}" href="../e-store/index.html" className="ult_colorlink ult-responsive  vc_custom_1543747552287" style={{fontFamily: 'Open Sans Condensed', fontWeight: 700}} data-textcolor="#213962" data-texthover="#ffb536" data-style> <span data-hover="Appliances" style={{color: '#213962'}} className="ult_btn10_span   ">Appliances</span> </a> </span> </span>
            </div>
          </div>
        </div> */}
        <div className="wpb_column vc_column_container vc_col-sm-4">
          <div className="vc_column-inner vc_custom_1498211158411">
            <div className="wpb_wrapper">
            <div className="shortcode-single-image-wrap alignnone vc_custom_1512971099006"><div className="shortcode-single-image"><div className="fancy-media-wrap" style={{borderRadius: '500px'}}><Link to="/electric-cooker-and-oven-repa" className="rollover this-ready layzr-bg-transparent" style={{borderRadius: '500px'}}><img className="lazy-load preload-me is-loaded" src="https://firstserveuk.co.uk/wp-content/uploads/2017/12/Cookers-1-220x220.png" width={220} height={220} data-dt-location="https://firstserveuk.co.uk/cookers-2/" alt="First Serve UK" srcSet="https://firstserveuk.co.uk/wp-content/uploads/2017/12/Cookers-1-220x220.png 220w, https://firstserveuk.co.uk/wp-content/uploads/2017/12/Cookers-1-259x259.png 259w" style={{willChange: 'auto'}} /><i /></Link></div></div></div>
              <span id="creative-link-wrap-4633" className="ult_main_cl ult-adjust-bottom-margin  "> <span className="  ult_crlink"> <Link data-ultimate-target="#creative-link-wrap-4633 .ult_colorlink" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:26px;&quot;,&quot;line-height&quot;:&quot;desktop:32px;&quot;}" to="/electric-cooker-and-oven-repa" className="ult_colorlink ult-responsive  vc_custom_1543747581136" style={{fontFamily: 'Open Sans Condensed', fontWeight: 700}} data-textcolor="#1f365c" data-texthover="#ff9f1a" data-style> <span data-hover="Cookers" style={{color: '#1f365c'}} className="ult_btn10_span   ">Cookers</span> </Link> </span> </span>
            </div>
          </div>
        </div>
        {/* <div className="wpb_column vc_column_container vc_col-sm-4">
          <div className="vc_column-inner vc_custom_1498211162818">
            <div className="wpb_wrapper">
              <div className="shortcode-single-image-wrap alignnone  vc_custom_1512971462916">
                <div className="shortcode-single-image">
                  <div className="fancy-media-wrap" style={{borderRadius: '500px'}}><a href="../corporate/services/service-inner/index.html" className="layzr-bg rollover" style={{borderRadius: '500px'}}><img className="lazy-load preload-me" src="data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20220%20220'%2F%3E" data-src="https://firstserveuk.co.uk/wp-content/uploads/2017/12/Sink-Unblocking-1-220x220.png" data-srcset="https://firstserveuk.co.uk/wp-content/uploads/2017/12/Sink-Unblocking-1-220x220.png 220w, https://firstserveuk.co.uk/wp-content/uploads/2017/12/Sink-Unblocking-1-346x346.png 346w" width={220} height={220} data-dt-location="https://firstserveuk.co.uk/sink-unblocking-2/" alt="First Serve UK" /></a></div>
                </div>
              </div>
              <span id="creative-link-wrap-3349" className="ult_main_cl ult-adjust-bottom-margin  "> <span className="  ult_crlink"> <a data-ultimate-target="#creative-link-wrap-3349 .ult_colorlink" data-responsive-json-new="{&quot;font-size&quot;:&quot;desktop:26px;&quot;,&quot;line-height&quot;:&quot;desktop:32px;&quot;}" href="../blocked-sinks-and-drains/index.html" className="ult_colorlink ult-responsive  vc_custom_1543747597924" style={{fontFamily: 'Open Sans Condensed', fontWeight: 700}} data-textcolor="#1f365c" data-texthover="#ffb536" data-style> <span data-hover="Sink Unblocking" style={{color: '#1f365c'}} className="ult_btn10_span   ">Sink Unblocking</span> </a> </span> </span>
            </div>
          </div>
        </div> */}
      </div>
            </div>
          </div>
        </div>

        <Booking />
      </div>
    </React.Fragment>
  );
};
export default ServicesContent;
