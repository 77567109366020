import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Markup } from "interweave";
import $ from "jquery";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import GetbaseUrl from "../base_url";

export class NewsGrids extends Component {
  constructor(props) {
    super(props);
    var url = GetbaseUrl();

    this.state = {
      baseUrl: url,
      newsList: [],
      message: "",
      isLoaded: false
    };
  }
  componentDidMount() {
    this.getNewsApi();
  }
  getNewsApi = () => {
    let insertUrl = this.state.baseUrl + "/News/getNewsApi";
    // console.log(insertUrl);
    new Promise(function (resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST"
      }).then(
        function (data) {
          resolve(data);
        },
        function (err) {
          reject(err);
        }
      );
    })
      .then(result => {
        if (result.success) {
          // console.log(result);
          this.setState({
            newsList: result.news,
            isLoaded: true
          });
        } else {
          this.setState({
            message: result.message,
            isLoaded: true
          });
          // console.log(result.message);
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          message: "No news Found!",
          isLoaded: true
        });
      });
  };
  render() {
    const { newsList, isLoaded } = this.state;
    return (
      <React.Fragment>
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="col-md-12">
                  <h3 className="pb-3 mb-4 font-italic border-bottom">
                    News on First Serve UK
                  </h3>
                  <div className="row">
                    {isLoaded ? (
                      newsList ? (
                        newsList.map((item, index) => {
                          return (
                            <div
                              className="card col-md-4 flex-md-row mb-4 shadow-sm h-md-250"
                              key={index}
                            >
                              <div className="card-body d-flex flex-column align-items-center custom-class">
                                <img
                                  className="card-img-right flex-auto d-none d-lg-block"
                                  alt={"News Blog " + index + 1}
                                  src={
                                    this.state.baseUrl +
                                    "/admin/asset/img/news/" +
                                    item.image
                                  }
                                  style={{ width: "300px", height: "250px" }}
                                />
                                <strong className="d-inline-block mb-2 text-primary">
                                  {item.name}
                                </strong>
                                <h4 className="entry-title">
                                  {" "}
                                  <Link
                                    to={{
                                      pathname:
                                        "/" +
                                        item.name
                                          .replace(/\s+/g, "-")
                                          .toLowerCase()
                                          .replace("?", ""),
                                      state: {
                                        newsList: item
                                      }
                                    }}
                                    title={item.name}
                                    rel="bookmark"
                                  >
                                    {item.description}
                                  </Link>
                                </h4>
                                {/* <h6 className="mb-0">
                                      <a className="text-dark" href="#">
                                        {item.description}
                                      </a>
                                    </h6> */}
                                <p className="card-text mb-auto">
                                  <Markup content={item.newsstory} />
                                </p>
                                <div className="mb-1 text-muted small">
                                  {item.created_at}
                                </div>
                                <Link
                                  className="btn btn-outline-primary btn-sm"
                                  role="button"
                                  to={{
                                    pathname:
                                      "/" +
                                      item.name
                                        .replace(/\s+/g, "-")
                                        .toLowerCase()
                                        .replace("?", ""),
                                    state: {
                                      newsList: item
                                    }
                                  }}
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                          "No news blogs available"
                        )
                    ) : (
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height="100"
                          width="100"
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
