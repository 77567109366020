import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Booking from "../MUC/booking.jsx";
import Services from "../MUC/services.jsx";
import { Link } from "react-router-dom";

class WashingChelsea extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Washing Machine Repairs in Chelsea | First Serve UK</title>
                    <meta
                        name="description"
                        content="Washing Machine Repairs in Chelsea. We provide Same/Next Day Service, 24-Hour Online Booking or Call 07984 670 782 for Washing Machine Repairs Chelsea"
                    />
                    <link
                        rel="canonical"
                        href="https://firstserveuk.co.uk/washing-machine-repairs-chelsea"
                    />
                </Helmet>
                <div className="page-title title-left solid-bg page-title-responsive-enabled">
                    <div className="wf-wrap">
                        <div className="page-title-head hgroup">
                            <h1>Washing Machine Repairs Chelsea </h1>
                        </div>
                        <div className="page-title-breadcrumbs">
                            <div className="assistive-text">You are here:</div>
                            <ol className="breadcrumbs text-small">
                                <li typeof="v:Breadcrumb">
                                    <Link to="/">Home</Link>
                                </li>
                                <li typeof="v:Breadcrumb">
                                    <Link to="/areas-we-cover">Areas We Cover</Link>
                                </li>
                                <li className="current">
                                    Washing Machine Repairs Chelsea
                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* Content */}
                <React.Fragment>
                    <div id="main" className="sidebar-right sidebar-divider-off">
                        <div className="main-gradient" />
                        <div className="wf-wrap">
                            <div className="wf-container-main">
                                <div id="content" className="content" role="main">

                                    <div className="vc_row wpb_row vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-3">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_single_image wpb_content_element vc_align_left">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div className="vc_single_image-wrapper   vc_box_border_grey"><img width={293} height={196} src="./assets/images/Machine.jpg" className="vc_single_image-img attachment-full" alt="First Serve UK" data-dt-location="https://firstserveuk.co.uk/save/" /></div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container vc_col-sm-9">
                                            <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                    <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                            <p>One of the most flourishing areas of London, Chelsea is known for the National Army Museum, which opened in 1971.<br /><br />

                                                                Just 30 years later, First Serve UK washing  machine repairs established its services to meet the upcoming demand for London based engineers who were sensitive to the fast paced community of London where time is more valuable than money. <br /><br />

                                                                Here at  First Serve UK we understand the importance of keeping your time saving appliances in good working order. <br /><br />

                                                                Our aim is to give our clients absolute peace of mind when it comes to getting their appliances fixed on the same or next day service.<br /><br />

                                                                We understand how busy life gets in London and how schedules get hampered once the laundry goes wrong. Not to mention how crucial it is to have an engineer attend your rented property and rectify the issues from start to finish.<br /><br />

                                                                With over 300 five-star reviews on Google, we hope to continue to be your first choice for Washing Machine Repairs in Kensington. <br /><br />




                                                                <br />

                                                                <b>Book your appointment online today:</b><br />
                                                                <section className="vc_section">
                                                                    <div className="vc_row wpb_row vc_row-fluid">
                                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                            <div className="vc_column-inner ">
                                                                                <div className="wpb_wrapper">
                                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                                        <div className="wpb_wrapper">
                                                                                            <div id={1} className="widgets_on_page wop_tiny1  wop_small1  wop_medium1  wop_large1  wop_wide1">
                                                                                                <ul>
                                                                                                    <li id="text-7" className="widget widget_text">
                                                                                                        <div className="textwidget">
                                                                                                            <ul>
                                                                                                                <li><a className="cta-button" title href="http://firstserveuk.simplybook.it/v2/#book" target="_blank" rel="noopener noreferrer">Same Day Service</a></li>
                                                                                                                <li><a className="cta-button cta-button-2" title href="http://firstserveuk.simplybook.it/v2/#book" target="_blank" rel="noopener noreferrer">Next Day Service</a></li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section><br />

                                                                <b>Or ring 07984670782</b><br />

                                                                <b>Thank you</b><br />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <aside id="sidebar" className="sidebar bg-under-widget">
                                    <div className="sidebar-content widget-divider-off">
                                        <section id="text-9" className="widget widget_text" style={{
                                            background: "#014485",
                                            borderRadius: "5px"
                                        }}>
                                            <div className="textwidget" style={{ marginBottom: "10px" }}>
                                                <div className="sidebar-cta">
                                                    <h2 style={{ color: "#ffffff" }}><strong style={{ color: "#ffffff", fontWeight: "700" }}>Same Day/Next Day Service!</strong> FREE Extra Repair With <a href="http://firstserveuk.simplybook.it/v2/" target="_blank" style={{ color: '#fff', textDecoration: 'underline' }} rel="noopener noreferrer">Online Bookings</a></h2>
                                                    <ul style={{ color: "#ffffff" }}>
                                                        <li>Local Washing Machine Repair Specialists</li>
                                                        <li>Same Day or Next Day Service</li>
                                                        <li>1 Year Guarantee</li>
                                                    </ul>
                                                    <p><a style={{
                                                        backgroundColor: "#ffb536", padding: "20px", margin: "0", display: "block", color: "#000000",
                                                        backgroundImage: "linear-gradient(to bottom,rgba(255,255,255,.2) 0,rgba(255,255,255,.01) 100%)",
                                                        backgroundRepeat: "repeat-x", textDecoration: "none", borderRadius: "7px", maxWidth: "360px",
                                                        lineHeight: "1.2", textAlign: "center", cursor: "pointer",
                                                        font: "normal 15px/23px `Arial`,Helvetica,Arial,Verdana,sans-serif",
                                                        wordSpacing: "normal"
                                                    }} href="http://firstserveuk.simplybook.it/v2/" target="_blank" rel="noopener noreferrer"><strong style={{ color: "#000000", fontSize: "26px" }}>07984 670 782</strong> <br /><span style={{ textDecoration: 'underline', fontSize: "20px" }}>24-Hour Online Booking</span></a></p>
                                                </div>
                                            </div>
                                        </section>
                                        <section id="presscore-custom-menu-1-2" className="widget widget_presscore-custom-menu-1">
                                            <div className="widget-title">Services</div>
                                            <Services />
                                        </section>
                                        <section id="text-3" className="widget widget_text">
                                            <div className="textwidget">
                                                <h4 style={{ color: '#fff', background: '#013c7a' }}>Contact Us</h4>
                                                <div role="form" className="wpcf7" id="wpcf7-f4-o1" lang="en-US" dir="ltr">
                                                    <div className="screen-reader-response" />
                                                    <form action="https://firstserveuk.co.uk/about-us/#wpcf7-f4-o1" method="post" className="wpcf7-form" noValidate="novalidate">
                                                        <div style={{ display: 'none' }}>
                                                            <input type="hidden" name="_wpcf7" defaultValue={4} />
                                                            <input type="hidden" name="_wpcf7_version" defaultValue="5.1.1" />
                                                            <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" />
                                                            <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f4-o1" />
                                                            <input type="hidden" name="_wpcf7_container_post" defaultValue={0} />
                                                            <input type="hidden" name="g-recaptcha-response" defaultValue />
                                                        </div>
                                                        <p>
                                                            <span className="wpcf7-form-control-wrap your-name">
                                                                <input type="text" name="your-name" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Full Name" />
                                                            </span>
                                                            <br />
                                                            <span className="wpcf7-form-control-wrap your-email">
                                                                <input type="text" name="your-email" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Email Address" />
                                                            </span>
                                                            <br />
                                                            <span className="wpcf7-form-control-wrap your-Telephone">
                                                                <input type="text" name="your-Telephone" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Telephone Number" />
                                                            </span>
                                                            <br />
                                                            <span className="wpcf7-form-control-wrap makeModelofAppliance">
                                                                <input type="text" name="makeModelofAppliance" size={40} className="wpcf7-form-control wpcf7-text" placeholder="Make Model of Appliance" />
                                                            </span>
                                                            <br />
                                                            <span className="wpcf7-form-control-wrap NatureofFault">
                                                                <input type="text" name="NatureofFault" size={40} className="wpcf7-form-control wpcf7-text" placeholder="Nature of Fault" />
                                                            </span>
                                                            <br />
                                                            <span className="wpcf7-form-control-wrap Comment">
                                                                <textarea name="Comment" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" placeholder="Comment..." />
                                                            </span>
                                                        </p>
                                                        <p><input type="submit" defaultValue="SUBMIT" className="wpcf7-form-control wpcf7-submit" /></p>
                                                        <div className="wpcf7-response-output wpcf7-display-none" />
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </aside>
                            </div>
                        </div>
                        <Booking />
                    </div>
                </React.Fragment>
                {/* END Content */}
            </React.Fragment>
        );
    }
}

export default WashingChelsea;
