import React from "react";
import Booking from "../MUC/booking.jsx";
import { NewsGrids } from "./components";

const NewsContent = () => {
  return (
    <React.Fragment>
      <div id="main" className="sidebar-right sidebar-divider-off">
        <div className="main-gradient" />
        <div className="wf-wrap">
          <div className="#">
            <div id="content" className="content" role="main">
              <NewsGrids />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default NewsContent;
