import React from "react";
import { render } from "react-dom";

class Slider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <div className="vc_row wpb_row vc_row-fluid hero-banner">
          <div className="main-slider--column wpb_column vc_column_container vc_col-sm-8">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper" />
            </div>
          </div>
          <div className="main-slider wpb_column vc_column_container vc_col-sm-4">
            <div className="vc_column-inner vc_custom_1510821630298">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element  hero-banner--caption">
                  <div className="wpb_wrapper">
                    <div className="hero-cta--inner">
                      <h2>
                        <strong>Same Day/Next Day Service! </strong>
                        Welcome to peace of mind! Welcome to First Serve UK!
                      </h2>
                      <ul>
                        <li>Local Washing Machine Repair Specialists</li>
                        <li>Same Day or Next Day Service</li>
                        <li>1 Year Guarantee</li>
                      </ul>
                      <p>
                        <a
                          className="hero-button"
                          href="http://firstserveuk.simplybook.it/v2/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <strong>07984 670 782</strong>{" "}
                          <span style={{ textDecoration: "underline" }}>
                            24-Hour Online Booking
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="vc_row wpb_row vc_row-fluid hero-banner ult-vc-hide-row vc_row-has-fill"
          data-rtl="false"
          style={{ position: "relative" }}
          data-row-effect-mobile-disable="true"
          data-img-parallax-mobile-disable="true"
        >
          <div
            className="upb_row_bg vcpb-default"
            data-upb_br_animation
            data-parallax_sense={30}
            data-bg-override={0}
            data-bg-animation="left-animation"
            data-bg-animation-type="h"
            data-animation-repeat="repeat"
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "repeat",
              backgroundColor: "rgba(0, 0, 0, 0)",
              backgroundImage:
                `url(${this.props.baseUrl + "asset/img/cover/" + this.props.data.bg_img})`,
              backgroundAttachment: "scroll",
              minWidth: "1190px",
              left: "0px",
              width: "1190px"
            }}
          />
          <div className="main-slider--column wpb_column vc_column_container vc_col-sm-8">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper" />
            </div>
          </div>
          <div className="main-slider wpb_column vc_column_container vc_col-sm-4">
            <div className="vc_column-inner vc_custom_1510821630298">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element  hero-banner--caption">
                  <div className="wpb_wrapper">
                    <div className="hero-cta--inner">
                      <h1>
                        <strong style={{ color: "white" }}>
                          {this.props.data.right_heading}
                        </strong>
                      </h1>
                      <h2>
                        {this.props.data.right_subheading}
                      </h2>
                      <ul>
                        <li style={{ color: "white" }}>{this.props.data.right_text1}</li>
                        <li style={{ color: "white" }}>{this.props.data.right_text2}</li>
                        <li style={{ color: "white" }}>{this.props.data.right_text3}</li>
                      </ul>
                      <p>
                        <a
                          className="hero-button"
                          href="http://firstserveuk.simplybook.it/v2/"
                          target="_blank"
                          rel="noopener"
                        >
                          <strong>{this.props.data.phone}</strong>{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {this.props.data.phone_text}
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="vc_row wpb_row vc_row-fluid hero-badge--inner">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element  hero-badge--caption">
                  <div className="wpb_wrapper">
                    <h3>Washing Machine Repairs</h3>
                    <h4>
                      5 Star Customer Service <strong>Guaranteed!</strong>
                    </h4>
                  </div>
                </div>
                <div className="wpb_single_image wpb_content_element vc_align_center   hero-badge--badge">
                  <figure className="wpb_wrapper vc_figure">
                    <div className="vc_single_image-wrapper   vc_box_border_grey">
                      <img
                        width={220}
                        height={195}
                        src="./assets/images/5-Star-Customer-Service-First-Serve-Uk.png"
                        className="vc_single_image-img attachment-full"
                        alt="5-Star-Customer-Service-First-Serve-Uk"
                        data-dt-location="home/5-star-customer-service-first-serve-uk/index.html"
                      />
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="vc_row wpb_row vc_row-fluid hero-badge--inner">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element  hero-badge--caption">
                  <div className="wpb_wrapper">
                    <h3>{this.props.data.left_heading}</h3>
                    <h4>
                      {this.props.data.left_subheading}
                    </h4>
                  </div>
                </div>
                <div className="wpb_single_image wpb_content_element vc_align_center   hero-badge--badge">
                  <figure className="wpb_wrapper vc_figure">
                    <div className="vc_single_image-wrapper   vc_box_border_grey">
                      <img
                        width={220}
                        height={195}
                        src={this.props.baseUrl + "asset/img/cover/" + this.props.data.star_img}
                        className="vc_single_image-img attachment-full"
                        alt="5-Star-Customer-Service-First-Serve-Uk"
                      />
                    </div>
                    {/* {this.props.baseUrl + "asset/img/cover/" + this.props.data.star_img}   */}
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

  }
  componentDidMount() {

  }
};
export default Slider;
