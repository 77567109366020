import React, { Component } from "react";
import Header from "../../components/public/Header/Header";
import Footer from "../../components/public/Footer/Footer";
import MainCss from "../../components/public/css/MainCss";
import MainJS from "../../components/public/js/mainJS";
import ControlsLast from "../../components/public/MUC/theme-last-content";

class Layout extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);

    localStorage.setItem("checked", false);
  }
  renderTemplate = type => {
    let siteTemplate = null;
    switch (type) {
      case "publicSite":
        siteTemplate = (
          <React.Fragment>
            <MainCss />
            <div id="load" className="ring-loader">
              <div className="load-wrap" />
            </div>
            <div id="page" className="boxed">
              <a className="skip-link screen-reader-text" href="#content">
                Skip to content
              </a>
              <Header />
              {this.props.children}
              <Footer />
            </div>
            <ControlsLast />
            <MainJS />
          </React.Fragment>
        );
        break;
      default:
        siteTemplate = null;
    }
    return siteTemplate;
  };
  render() {
    if (this.props) return <div>{this.renderTemplate(this.props.type)}</div>;
  }
}

export default Layout;
