import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Booking from "../MUC/booking";
import Services from "../MUC/Summary";
import Reviews from "./Reviews";
import News from "./News";
import Twitter from "./Twitter";
import Form from "./Form";
import Slider from "./Slider";
import $ from "jquery";
import GetbaseUrl from "../base_url";

class Home extends Component {
  constructor(props) {
    super(props);
    var url = GetbaseUrl();
    this.state = {
      baseUrl: url,
      isLoaded: false,
      message:"",
      coverData : [],
      usp1:[],
      usp2:[],
      footerBannerData : [] ,
    };
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Washing Machine Repairs London | First Serve UK</title>
          <meta
            name="description"
            content="Washing Machine Repairs London. We provide Same/Next Day Service, 24-Hour
            Online Booking, Call 07984 670 782 for Local Washing Machine Repairs Specialists."
          />
          <link rel="canonical" href="https://firstserveuk.co.uk/" />
        </Helmet>
        <div id="main" className="sidebar-none sidebar-divider-off">
          <div className="main-gradient" />
          <div className="wf-wrap">
            <div className="wf-container-main">
              <div id="content" className="content" role="main">
                <Slider data={this.state.coverData} isLoaded={this.state.isLoaded} baseUrl={this.state.baseUrl}  />
                <div className="vc_row wpb_row vc_row-fluid homepage-row-1">
                  <Reviews />
                  <Services dataUsp1={this.state.usp1} dataUsp2={this.state.usp2} isLoaded={this.state.isLoaded}/>
                </div>
                <div className="vc_row wpb_row vc_row-fluid homepage-row-2">
                  <div className="blog-height cs-col-1 wpb_column vc_column_container vc_col-sm-6">
                    <News />
                  </div>
                  <div className="col_twitter cs-col-2 wpb_column vc_column_container vc_col-sm-3">
                    <Twitter />
                  </div>
                  <div className="contact-form-sidebar-widget cs-col-3 wpb_column vc_column_container vc_col-sm-3">
                    <Form />
                  </div>
                </div>
                <Booking data={this.state.footerBannerData} loadStatus={this.state.isLoaded}  />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  
  componentWillMount()
  {
    this.getHomeData();
  }
  getHomeData = () => {
    let insertUrl = this.state.baseUrl + "getHomeData";
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST"
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        
        this.setState({
          coverData:result.coverData[0],
          usp1:result.grp1,
          usp2:result.grp2,
          footerBannerData :result.footerbanner[0],
          isLoaded:true
        });
        // console.log(result);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          message: "No news Found!",
          isLoaded: true
        });
      });
  };
}

export default Home;
