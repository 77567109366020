import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import GetbaseUrl from "../base_url";

class Header extends Component {
   constructor(props) {
      super(props);
      var url = GetbaseUrl();
      this.state = {
         baseUrl: url,
         logoName: {},
         contactNumber: "",
         social: {},
         loading: true,
      }
   }
   toggle = (menuItem) => {
      if (menuItem === "isHome") {
         $("#AreasWeCover").removeClass("act first");
         $("#isAboutUs").removeClass("act first");
         $("#services").removeClass("act first");
         $("#news").removeClass("act first");
         $("#contact_us").removeClass("act first");
         $("#testimonials").removeClass("act first");
         $("#isHome").addClass("act first");
      } else if (menuItem === "isAboutUs" || menuItem === "isAboutUsTrainingArticle" || menuItem === "isAboutUsTraining") {
         $("#isHome").removeClass("act first");
         $("#AreasWeCover").removeClass("act first");
         $("#services").removeClass("act first");
         $("#news").removeClass("act first");
         $("#contact_us").removeClass("act first");
         $("#testimonials").removeClass("act first");
         $("#isAboutUs").addClass("act first");
      } else if (menuItem === "AreasWeCover") {
         $("#isHome").removeClass("act first");
         $("#isAboutUs").removeClass("act first");
         $("#services").removeClass("act first");
         $("#news").removeClass("act first");
         $("#contact_us").removeClass("act first");
         $("#testimonials").removeClass("act first");
         $("#AreasWeCover").addClass("act first");
      } else if (menuItem === "services") {
         $("#isHome").removeClass("act first");
         $("#isAboutUs").removeClass("act first");
         $("#AreasWeCover").removeClass("act first");
         $("#news").removeClass("act first");
         $("#contact_us").removeClass("act first");
         $("#testimonials").removeClass("act first");
         $("#services").addClass("act first");
      } else if (menuItem === "news") {
         $("#isHome").removeClass("act first");
         $("#isAboutUs").removeClass("act first");
         $("#AreasWeCover").removeClass("act first");
         $("#services").removeClass("act first");
         $("#contact_us").removeClass("act first");
         $("#testimonials").removeClass("act first");
         $("#news").addClass("act first");
      } else if (menuItem === "testimonials") {
         $("#isHome").removeClass("act first");
         $("#isAboutUs").removeClass("act first");
         $("#AreasWeCover").removeClass("act first");
         $("#services").removeClass("act first");
         $("#news").removeClass("act first");
         $("#contact_us").removeClass("act first");
         $("#testimonials").addClass("act first");
      } else if (menuItem === "contact_us") {
         $("#isHome").removeClass("act first");
         $("#isAboutUs").removeClass("act first");
         $("#AreasWeCover").removeClass("act first");
         $("#services").removeClass("act first");
         $("#news").removeClass("act first");
         $("#testimonials").removeClass("act first");
         $("#contact_us").addClass("act first");
      }
   }
   linkCalled = () => {
      // $("#bodyTag").removeClass("show-overlay-mobile-header show-sticky-mobile-header");
      // $("#bodyTag").addClass("closed-overlay-mobile-header");
      // $("#page").removeClass("boxed show-mobile-header");
      // $("#page").addClass("boxed closed-mobile-header");
      // $(".mobile-sticky-header-overlay").removeClass("active");
   }
   componentDidMount() {
      this.getNewsApi();
   }
   getNewsApi = () => {

      let insertUrl = this.state.baseUrl + "getHeaderData";
      // console.log(insertUrl);
      new Promise(function (resolve, reject) {
         $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST"
         }).then(
            function (data) {
               resolve(data);
            },
            function (err) {
               reject(err);
            }
         );
      })
         .then(result => {
            // console.log(result);
            this.setState({
               logoName: result['header'][0]['logo'],
               contactNumber: result['header'][0]['contact_no'],
               contactTitle: result['header'][0]['contact_title'],
               social: result['social'],
               loading: false
            });

         })
         .catch(err => {
            console.log(err);
            //  this.setState({
            //    message: "No news Found!",
            //    isLoaded: true
            //  });
         });
   };
   render() {
      const { loading, social, logoName, contactTitle, contactNumber } = this.state;

      return (
         <React.Fragment>
            {/* Header Start */}
            <div className="masthead classic-header justify full-width-line widgets dividers surround small-mobile-menu-icon mobile-menu-icon-bg-on dt-parent-menu-clickable show-mobile-logo" role="banner">
               <div className="top-bar top-bar-empty top-bar-line-hide">
                  <div className="top-bar-bg" />
                  <div className="mini-widgets left-widgets" />
                  <div className="mini-widgets right-widgets" />
               </div>
               <header className="header-bar">
                  <div className="branding">
                     <div id="site-title" className="assistive-text">First Serve UK</div>
                     <div id="site-description" className="assistive-text">Washing Machine Repairs London – First Serve UK</div>
                     <a href="#">
                        {/* {console.log(header[0].logo)} */}
                        {/* this.state.baseUrl + "asset/img/header/" + logoName */}
                        <img className=" preload-me" src={`${this.state.baseUrl}asset/img/footer/d8d19782dadfa54a12a2215ff80048de.png`} srcSet={`${this.state.baseUrl}asset/img/footer/d8d19782dadfa54a12a2215ff80048de.png 233w, ${this.state.baseUrl}asset/img/footer/d8d19782dadfa54a12a2215ff80048de.png 150w`} width={233} height={150} sizes="233px" alt="Logo - First Serve UK" />

                        <img style={{ marginLeft: "7%" }} className="mobile-logo preload-me" src={`${this.state.baseUrl}asset/img/footer/d8d19782dadfa54a12a2215ff80048de.png`} srcSet={`${this.state.baseUrl}asset/img/footer/d8d19782dadfa54a12a2215ff80048de.png 233w, ${this.state.baseUrl}asset/img/footer/d8d19782dadfa54a12a2215ff80048de.png 150w`} width={233} height={150} sizes="233px" alt="First Serve UK" />

                     </a>
                     <div className="mini-widgets" />
                     <div className="mini-widgets">
                        <div className="text-area show-on-desktop near-logo-first-switch near-logo-second-switch">
                           <ul className="head-social">


                              {loading == false ?
                                 social.map((obj, index) => {
                                    return (
                                       <React.Fragment key={index}>
                                          <li><a title={obj.title} href={obj.link} target="_blank" rel="noopener noreferrer" >{obj.title}</a>
                                          </li>
                                          {index == social.length - 1 ? "" : <li className="head-social--divider">|
                                  </li>}
                                       </React.Fragment>
                                    );
                                 }

                                 )
                                 : ""
                              }

                              {/* <li><a title="Facebook" href="https://www.facebook.com/firstserveuk.co.uk/" target="_blank" rel="noopener noreferrer" className="facebook">Facebook</a></li> 
                     <li className="head-social--divider">|
                     </li>
                     <li><a title="twitter" href="https://twitter.com/1stserveuk" target="_blank" rel="noopener noreferrer" className="twitter">Twitter</a></li>
                    
                     <li><a title="youtube" href="https://www.youtube.com/user/firstserveuk/featured" target="_blank" rel="noopener noreferrer" className="youtube">Youtube</a></li>  */}
                           </ul>
                        </div>
                        <div className="text-area show-on-desktop near-logo-first-switch near-logo-second-switch">
                           <p ><span className="head-txt" style={{ marginLeft: "5%" }}>{contactTitle}</span><br /> <a href="tel:" className="head-number">{contactNumber}</a></p>
                        </div>
                        <div className="soc-ico show-on-desktop in-menu-first-switch in-menu-second-switch custom-bg custom-border border-on hover-accent-bg hover-disabled-border  hover-border-off"><a title="Facebook" href="https://www.facebook.com/firstserveuk.co.uk/" target="_blank" rel="noopener noreferrer" className="facebook"><span className="soc-font-icon" /><span className="screen-reader-text">Facebook</span></a><a title="Twitter" href="https://twitter.com/1stserveuk" target="_blank" rel="noopener noreferrer" className="twitter"><span className="soc-font-icon" /><span className="screen-reader-text">Twitter</span></a><a title="Instagram" href="https://www.instagram.com/firstserve_uk/" target="_blank" rel="noopener noreferrer" className="instagram"><span className="soc-font-icon" /><span className="screen-reader-text">Instagram</span></a></div>
                     </div>
                  </div>
                  <nav className="navigation">
                     <ul id="primary-menu" className="main-nav underline-decoration upwards-line" role="menu">
                        <li className="menu-item  menu-item-object-page menu-item-home current-menu-item page_item page-item-1731 current_page_item menu-item-2595 " id="isHome" onClick={() =>
                           this.toggle("isHome")}>
                           <Link to="/" data-level={1} onClick={this.linkCalled}>
                              <i className="fa fa-home" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Home</span></span>
                           </Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-has-children menu-item-2665 has-children " id="isAboutUs" onClick={() => this.toggle("isAboutUs")}>
                           <Link to="about-us" data-level={1} onClick={this.linkCalled}>
                              <i className="fa fa-address-card" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">About Us</span></span></Link>
                           <ul className="sub-nav">
                              <li className="menu-item  menu-item-object-page menu-item-2598 " id="isAboutUsTrainingArticle" onClick={() =>
                                 this.toggle("isAboutUsTrainingArticle")}>
                                 <Link to="training-article" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Training Article</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2597 " id="isAboutUsTraining" onClick={() =>
                                 this.toggle("isAboutUsTraining")}>
                                 <Link to="training" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Training</span></span></Link>
                              </li>
                           </ul>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-has-children menu-item-2599 has-children " id="AreasWeCover" onClick={() =>
                           this.toggle("AreasWeCover")}>
                           <Link to="areas-we-cover" data-level={1} onClick={this.linkCalled}>
                              <i className="fa fa-map-marker" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Areas We Cover</span></span></Link>
                           <ul className="sub-nav">
                              <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="washing-machine-repairs-in-w1-london" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In W1 London</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="washing-machine-repairs-in-w2-london" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In W2 London</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="washing-machine-repairs-in-chiswick-w4-london" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In Chiswick W4 London </span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="washing-machine-repairs-chelsea" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs Chelsea</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="sw1w-and-sw1v-washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">SW1W And SW1V  Washing Machine Repairs</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="sw10-washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">SW10 Washing Machine Repairs</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="which-washing-machine" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Which Washing Machine?</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2601" onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="washer-dryer-efficiency" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Washer Dryer Efficiency</span></span></Link>
                              </li>
                              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2602" onClick={() =>
                                 this.toggle("AreasWeCover")}>
                                 <Link to="save-money" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Save Money</span></span></Link>
                              </li>
                           </ul>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-has-children menu-item-2603 has-children " id="services" onClick={() =>
                           this.toggle("services")}>
                           <Link to="services" data-level={1} onClick={this.linkCalled}>
                              <i className="fa fa-folder-open" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Our Services</span></span></Link>
                           <ul className="sub-nav">
                              <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                                 this.toggle("services")}>
                                 <Link to="washing-machine-repair-london" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs London</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                                 this.toggle("services")}>
                                 <Link to="washing-machine-repair-kensington" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs Kensington</span></span></Link>
                              </li>
                              <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                                 this.toggle("services")}>
                                 <Link to="washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs</span></span></Link>
                              </li>
                              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2605" onClick={() =>
                                 this.toggle("services")}>
                                 <Link to="tumbler-and-condenser-dryers" data-level={2} onClick={this.linkCalled}>
                                    <span className="menu-item-text"><span className="menu-text">Tumbler and Condenser Dryers</span></span></Link>
                              </li>
                              {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2606" onClick={() => this.toggle("services")}><a href="https://www.espares.co.uk/" target="_blank" rel="noopener noreferrer" data-level={2}><span className="menu-item-text"><span className="menu-text">Washing Machine Spares</span></span></a></li> */}
                              {/* 
                     <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2607" onClick={() =>
                        this.toggle("services")}>
                        <Link to="e-store" data-level={2}>
                        <span className="menu-item-text"><span className="menu-text">Appliances</span></span></Link>
                     </li>
                     */}
                              {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2608" onClick={() =>
                        this.toggle("services")}>
                        <Link to="electric-cooker-and-oven-repa" data-level={2} onClick={this.linkCalled}>
                        <span className="menu-item-text"><span className="menu-text">Cookers</span></span></Link>
                     </li> */}
                              {/* 
                     <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2609" onClick={() =>
                        this.toggle("services")}>
                        <Link to="blocked-sinks-and-drains" data-level={2}>
                        <span className="menu-item-text"><span className="menu-text">Sink Unblocking</span></span></Link>
                     </li>
                     */}
                              {/* <li className="menu-item  menu-item-object-page menu-item-2611" onClick={() =>
                        this.toggle("services")}>
                        <Link to="dishwashers" data-level={2} onClick={this.linkCalled}>
                        <span className="menu-item-text"><span className="menu-text">Dishwashers</span></span></Link>
                     </li> */}
                              {/* 
                     <li className="menu-item  menu-item-object-page menu-item-2610" onClick={() =>
                        this.toggle("services")}>
                        <Link to="central-heating" data-level={2}>
                        <span className="menu-item-text"><span className="menu-text">Central Heating</span></span></Link>
                     </li>
                     */}
                           </ul>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2612" id="news" onClick={() =>
                           this.toggle("news")}>
                           <Link to="news" data-level={1} onClick={this.linkCalled}>
                              <i className="fa fa-newspaper-o" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">News</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2613" id="testimonials" onClick={() =>
                           this.toggle("testimonials")}>
                           <Link to="testimonials" data-level={1} onClick={this.linkCalled}>
                              <i className="fa fa-thumbs-o-up" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Testimonials</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2614" id="contact_us" onClick={() =>
                           this.toggle("contact_us")}>
                           <Link to="contact-us" data-level={1} onClick={this.linkCalled}>
                              <i className="fa fa-phone" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Contact</span></span></Link>
                        </li>
                     </ul>
                     <div className="mini-widgets">
                        <div className="mini-search show-on-desktop in-menu-first-switch in-menu-second-switch">
                           <div className="searchform"> <label htmlFor="search" className="screen-reader-text">Search:</label> <input type="text" className="field searchform-s" name="s" placeholder="Type and hit enter …" /> <input type="submit" className="assistive-text searchsubmit" value="Go!" /> <a href="#go" id="trigger-overlay" className="submit mini-icon-off">SEARCH</a></div>
                        </div>
                     </div>
                  </nav>
               </header>
            </div>
            <div className="dt-close-mobile-menu-icon"><span /></div>
            <div className="dt-mobile-header">
               <ul id="mobile-menu" className="mobile-main-nav" role="menu">
                  <li className="menu-item  menu-item-object-page menu-item-home current-menu-item page_item page-item-1731 current_page_item menu-item-2595" id="isHome" onClick={() =>
                     this.toggle("isHome")}>
                     <Link to="/" data-level={1} onClick={this.linkCalled}>
                        <i className="fa fa-home" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Home</span></span></Link>
                  </li>
                  <li className="menu-item  menu-item-object-page menu-item-has-children menu-item-2665 has-children" id="isAboutUs" onClick={() =>
                     this.toggle("isAboutUs")}>
                     <Link to="about-us" data-level={1} onClick={this.linkCalled}>
                        <i className="fa fa-address-card" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">About Us</span></span></Link>
                     <ul className="sub-nav">
                        <li className="menu-item  menu-item-object-page menu-item-2598 " id="isAboutUsTrainingArticle" onClick={() => this.toggle("isAboutUsTrainingArticle")}><Link to="training-article" data-level={2} onClick={this.linkCalled}><span className="menu-item-text"><span className="menu-text">Training Article</span></span></Link></li>
                        <li className="menu-item  menu-item-object-page menu-item-2597" id="isAboutUsTraining" onClick={() =>
                           this.toggle("isAboutUsTraining")}><Link to="training" data-level={2} onClick={this.linkCalled}><span className="menu-item-text"><span className="menu-text">Training</span></span></Link></li>
                     </ul>
                  </li>
                  <li className="menu-item  menu-item-object-page menu-item-has-children menu-item-2599 has-children" id="AreasWeCover" onClick={() => this.toggle("AreasWeCover")}>
                     <Link to="areas-we-cover" data-level={1} onClick={this.linkCalled}><i className="fa fa-map-marker" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Areas We Cover</span></span></Link>
                     <ul className="sub-nav">
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                           this.toggle("AreasWeCover")}>
                           <Link to="washing-machine-repairs-in-w1-london" data-level={2} onClick={this.linkCalled}>
                              <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In W1 London</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                           this.toggle("AreasWeCover")}>
                           <Link to="washing-machine-repairs-in-w2-london" data-level={2} onClick={this.linkCalled}>
                              <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In W2 London</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                           this.toggle("AreasWeCover")}>
                           <Link to="washing-machine-repairs-in-chiswick-w4-london" data-level={2} onClick={this.linkCalled}>
                              <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In Chiswick W4 London </span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                           this.toggle("AreasWeCover")}>
                           <Link to="washing-machine-repairs-chelsea" data-level={2} onClick={this.linkCalled}>
                              <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs Chelsea</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                           this.toggle("AreasWeCover")}>
                           <Link to="sw1w-and-sw1v-washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                              <span className="menu-item-text"><span className="menu-text">SW1W And SW1V  Washing Machine Repairs</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                           this.toggle("AreasWeCover")}>
                           <Link to="sw10-washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                              <span className="menu-item-text"><span className="menu-text">SW10 Washing Machine Repairs</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() => this.toggle("AreasWeCover")}><Link to="which-washing-machine" data-level={2} onClick={this.linkCalled}><span className="menu-item-text"><span className="menu-text">Which Washing Machine?</span></span></Link></li>

                        <li className="menu-item  menu-item-object-page menu-item-2601" onClick={() => this.toggle("AreasWeCover")}><Link to="washer-dryer-efficiency" data-level={2} onClick={this.linkCalled}><span className="menu-item-text"><span className="menu-text">Washer Dryer Efficiency</span></span></Link></li>
                        {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2558" onClick={() => this.toggle("AreasWeCover")}><a href="first-serve-uk/e-store/index.html" data-level={2}><span className="menu-item-text"><span className="menu-text">Buy Appliances</span></span></a></li> */}
                        {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2602"><a href="first-serve-uk/save-money/index.html" data-level={2}><span className="menu-item-text"><span className="menu-text">Save Money</span></span></a></li> */}
                     </ul>
                  </li>
                  <li className="menu-item  menu-item-object-page menu-item-has-children menu-item-2603 has-children" id="services" onClick={() => this.toggle("services")}>
                     <Link to="services" data-level={1} onClick={this.linkCalled}><i className="fa fa-folder-open" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Our Services</span></span></Link>
                     <ul className="sub-nav">
                        <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                           this.toggle("services")}>
                           <Link to="washing-machine-repair-london" data-level={2} onClick={this.linkCalled}>
                              <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs London</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                           this.toggle("services")}>
                           <Link to="washing-machine-repair-kensington" data-level={2} onClick={this.linkCalled}>
                              <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs Kensington</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2604 " onClick={() => this.toggle("services")}><Link to="washing-machine-repairs" data-level={2} onClick={this.linkCalled}><span className="menu-item-text"><span className="menu-text">Washing Machine Repairs</span></span></Link></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2605" onClick={() => this.toggle("services")}><Link to="tumbler-and-condenser-dryers" data-level={2} onClick={this.linkCalled}><span className="menu-item-text"><span className="menu-text">Tumbler and Condenser Dryers</span></span></Link></li>
                        {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2606" onClick={() => this.toggle("services")}><a href="https://www.espares.co.uk/" target="_blank" rel="noopener noreferrer" data-level={2}><span className="menu-item-text"><span className="menu-text">Washing Machine Spares</span></span></a></li> */}
                        {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2607"><a href="first-serve-uk/e-store/index.html" data-level={2}><span className="menu-item-text"><span className="menu-text">Appliances</span></span></a></li> */}
                        {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2608" onClick={() => this.toggle("services")}><Link to="electric-cooker-and-oven-repa" data-level={2} onClick={this.linkCalled}><span className="menu-item-text"><span className="menu-text">Cookers</span></span></Link></li> */}
                        {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2609"><a href="first-serve-uk/blocked-sinks-and-drains/index.html" data-level={2}><span className="menu-item-text"><span className="menu-text">Sink Unblocking</span></span></a></li> */}
                        {/* <li className="menu-item  menu-item-object-page menu-item-2611" onClick={() => this.toggle("services")}><Link to="dishwashers" data-level={2} onClick={this.linkCalled}><span className="menu-item-text"><span className="menu-text">Dishwashers</span></span></Link></li> */}
                        {/* <li className="menu-item  menu-item-object-page menu-item-2610"><a href="central-heating/index.html" data-level={2}><span className="menu-item-text"><span className="menu-text">Central Heating</span></span></a></li> */}
                     </ul>
                  </li>
                  <li className="menu-item  menu-item-object-page menu-item-2612" id="news" onClick={() =>
                     this.toggle("news")}><Link to="news" data-level={1} onClick={this.linkCalled}><i className="fa fa-newspaper-o" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">News</span></span></Link></li>
                  <li className="menu-item  menu-item-object-page menu-item-2613" id="testimonials" onClick={() =>
                     this.toggle("testimonials")}><Link to="testimonials" data-level={1} onClick={this.linkCalled}><i className="fa fa-thumbs-o-up" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Testimonials</span></span></Link></li>
                  <li className="menu-item  menu-item-object-page menu-item-2614" id="contact_us" onClick={() =>
                     this.toggle("contact_us")}><Link to="contact-us" data-level={1} onClick={this.linkCalled}><i className="fa fa-phone" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Contact</span></span></Link></li>
               </ul>
               <div className="mobile-mini-widgets-in-menu" />
            </div>
            {/* #END# Mobile Header */}
         </React.Fragment>
      );
   };
};
export default Header;