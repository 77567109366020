import React from "react";
import Booking from "../MUC/booking.jsx";
import Services from "../MUC/services.jsx";
import { Link } from "react-router-dom";
import GetbaseUrl from "../base_url";
import $ from "jquery";

class TrainingContent extends React.Component {
   constructor(props) {
      var url = GetbaseUrl();
      super(props);
      this.state = {
        baseUrl: url,
      };
    }
    render()
  {
    const handleOnDragStart = e => e.preventDefault();
   return (
      <React.Fragment>
         <div id="main" className="sidebar-right sidebar-divider-off">
            <div className="main-gradient" />
            <div className="wf-wrap">
               <div className="wf-container-main">
                  <div id="content" className="content" role="main">
                     <div className="vc_row wpb_row vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                           <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                 <div id="ultimate-heading-6365d3a1aa2d4186" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-6365d3a1aa2d4186 uvc-5850 " data-hspacer="no_spacer" data-halign="left" style={{ textAlign: 'left' }}>
                                    <div className="uvc-heading-spacer no_spacer" style={{}} />
                                    <div className="uvc-main-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-6365d3a1aa2d4186 h2" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}"><h2 style={{ fontWeight: 'normal' }}>{this.props.isLoaded? this.props.data[0]["mainheading"]:""}</h2></div>
                                 </div>
                                 <div className="wpb_text_column wpb_content_element  vc_custom_1512798363383">
                                    <div className="wpb_wrapper" id="trainingcontent">
                                    {this.props.isLoaded?this.TrainingContent():this.loadeing()}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="vc_row wpb_row vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                           <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                 <div id="ultimate-heading-33835d3a1aa2d9023" className="uvc-heading ult-adjust-bottom-margin ultimate-heading-33835d3a1aa2d9023 uvc-1942 " data-hspacer="no_spacer" data-halign="left" style={{ textAlign: 'left' }}>
                                    <div className="uvc-heading-spacer no_spacer" style={{}} />
                                    <div className="uvc-main-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-33835d3a1aa2d9023 h2" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}"><h2 style={{ fontWeight: 'normal' }}>{this.props.isLoaded? this.props.data[0]["subheading"]:""}</h2></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="vc_row wpb_row vc_row-fluid vc_custom_1512798387129">
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                           <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                 <div className="wpb_single_image wpb_content_element vc_align_center">
                                    <figure className="wpb_wrapper vc_figure">
                                       <div className="vc_single_image-wrapper   vc_box_border_grey">
                                       <img width={433} height={156} 
                                            src={this.props.isLoaded? this.state.baseUrl + "asset/img/traning/" + this.props.data[0]['img1']:""}
                                             className="vc_single_image-img attachment-full" alt="First Serve UK"
                                             //  srcSet={this.props.isLoaded? this.state.baseUrl + "asset/img/training/" + this.props.data[0]['img1']:""+ "433w, " + this.props.isLoaded? this.state.baseUrl + "asset/img/training/" + this.props.data[0]['img1']:"" + "300w "} 
                                             sizes="(max-width: 433px) 100vw, 433px" data-dt-location="../comet/index.html" /></div>
                                    </figure>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                           <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                 <div className="wpb_single_image wpb_content_element vc_align_center">
                                    <figure className="wpb_wrapper vc_figure">
                                       <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img width={433} height={156} 
                                            src={this.props.isLoaded? this.state.baseUrl + "asset/img/traning/" + this.props.data[0]['img2']:""}
                                             className="vc_single_image-img attachment-full" alt="First Serve UK"
                                             //  srcSet={this.props.isLoaded? this.state.baseUrl + "asset/img/training/" + this.props.data[0]['img2']:""+ "433w, " + this.props.isLoaded? this.state.baseUrl + "asset/img/training/" + this.props.data[0]['img2']:"" + "300w "} 
                                             sizes="(max-width: 433px) 100vw, 433px" data-dt-location="../comet/index.html" /></div>
                                    </figure>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="vc_row wpb_row vc_row-fluid vc_custom_1517221178363">
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                           <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                 <div className="wpb_single_image wpb_content_element vc_align_left">
                                    <figure className="wpb_wrapper vc_figure">
                                {/* {this.props.isLoaded?console.log(this.props.data[0]['img1']) :console.log("loading........")} */}
                                {/* {this.state.baseUrl + "asset/images/" + this.props.isLoaded? this.props.data[0]['img1']:""} */}

                                       <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          
                                          <img  width={384} height={351} src={this.props.isLoaded? this.state.baseUrl + "asset/img/traning/" + this.props.data[0]['img3']:""}
                                                className="vc_single_image-img attachment-full" 
                                                alt="First Serve UK" 
                                                sizes="(max-width: 384px) 100vw, 384px" data-dt-location="../learn-how-to/index.html" />
                                          </div>
                                    </figure>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-6">
                           <div className="vc_column-inner ">
                              <div className="wpb_wrapper" /></div>
                        </div>
                     </div>
                  </div>
                  <aside id="sidebar" className="sidebar bg-under-widget">
                     <div className="sidebar-content widget-divider-off">
                        <section id="text-9" className="widget widget_text" style={{
                           background: "#014485",
                           borderRadius: "5px"
                        }}>
                           <div className="textwidget" style={{ marginBottom: "10px" }}>
                    <div className="sidebar-cta">
                      <h2 style={{ color: "#ffffff" }}><strong style={{ color: "#ffffff", fontWeight: "700" }}>{this.props.isLoaded? this.props.data[0]["right_side_main_content"]:""}</strong> {this.props.isLoaded? this.props.data[0]["right_side_main_content1"]:""} <a href="http://firstserveuk.simplybook.it/v2/" target="_blank" style={{ color: '#fff', textDecoration: 'underline' }} rel="noopener noreferrer">Online Bookings</a></h2>
                      <ul style={{ color: "#ffffff" }}>
                      <li>{this.props.isLoaded? this.props.data[0]["right_side_sub_content"]:""}</li>
                          <li>{this.props.isLoaded? this.props.data[0]["right_side_sub_content1"]:""}</li>
                          <li>{this.props.isLoaded? this.props.data[0]["Guarantee"]:""}</li>
                      </ul>
                      <p><a style={{
                        backgroundColor: "#ffb536", padding: "20px", margin: "0", display: "block", color: "#000000",
                        backgroundImage: "linear-gradient(to bottom,rgba(255,255,255,.2) 0,rgba(255,255,255,.01) 100%)",
                        backgroundRepeat: "repeat-x", textDecoration: "none", borderRadius: "7px", maxWidth: "360px",
                        lineHeight: "1.2", textAlign: "center", cursor: "pointer",
                        font: "normal 15px/23px `Arial`,Helvetica,Arial,Verdana,sans-serif",
                        wordSpacing: "normal"
                      }} href="http://firstserveuk.simplybook.it/v2/" target="_blank" rel="noopener noreferrer"><strong style={{ color: "#000000", fontSize: "26px" }}>{this.props.isLoaded? this.props.data[0]["right_side_phone_no"]:""}</strong> <br /><span style={{ textDecoration: 'underline', fontSize: "20px" }}>24-Hour Online Booking</span></a></p>
                    </div>
                  </div>
                        </section>
                        <section id="presscore-custom-menu-1-2" className="widget widget_presscore-custom-menu-1">
                           <div className="widget-title">Services</div>
                           <Services />
                        </section>
                        <section id="text-3" className="widget widget_text">
                           <div className="textwidget">
                              <h4 style={{ color: '#fff', background: '#013c7a' }}>Contact Us</h4>
                              <div role="form" className="wpcf7" id="wpcf7-f4-o1" lang="en-US" dir="ltr">
                                 <div className="screen-reader-response" />
                                 <form action="https://firstserveuk.co.uk/about-us/#wpcf7-f4-o1" method="post" className="wpcf7-form" noValidate="novalidate">
                                    <div style={{ display: 'none' }}>
                                       <input type="hidden" name="_wpcf7" defaultValue={4} />
                                       <input type="hidden" name="_wpcf7_version" defaultValue="5.1.1" />
                                       <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" />
                                       <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f4-o1" />
                                       <input type="hidden" name="_wpcf7_container_post" defaultValue={0} />
                                       <input type="hidden" name="g-recaptcha-response" defaultValue />
                                    </div>
                                    <p>
                                       <span className="wpcf7-form-control-wrap your-name">
                                          <input type="text" name="your-name" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Full Name" />
                                       </span>
                                       <br />
                                       <span className="wpcf7-form-control-wrap your-email">
                                          <input type="text" name="your-email" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Email Address" />
                                       </span>
                                       <br />
                                       <span className="wpcf7-form-control-wrap your-Telephone">
                                          <input type="text" name="your-Telephone" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" placeholder="Telephone Number" />
                                       </span>
                                       <br />
                                       <span className="wpcf7-form-control-wrap makeModelofAppliance">
                                          <input type="text" name="makeModelofAppliance" size={40} className="wpcf7-form-control wpcf7-text" placeholder="Make Model of Appliance" />
                                       </span>
                                       <br />
                                       <span className="wpcf7-form-control-wrap NatureofFault">
                                          <input type="text" name="NatureofFault" size={40} className="wpcf7-form-control wpcf7-text" placeholder="Nature of Fault" />
                                       </span>
                                       <br />
                                       <span className="wpcf7-form-control-wrap Comment">
                                          <textarea name="Comment" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" placeholder="Comment..." />
                                       </span>
                                    </p>
                                    <p><input type="submit" defaultValue="SUBMIT" className="wpcf7-form-control wpcf7-submit" /></p>
                                    <div className="wpcf7-response-output wpcf7-display-none" />
                                 </form>
                              </div>
                           </div>
                        </section>
                     </div>
                  </aside>
               </div>
            </div>
            <Booking />
         </div>
      </React.Fragment>
   );
}
TrainingContent =()=>{

   $("#trainingcontent").html(this.props.data[0]["mainheadingcontent"]);
 }
 loadeing =()=>{
   $("#trainingcontent").html("loadeing ... ");
 }
};
export default TrainingContent;
