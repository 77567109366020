import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import GetbaseUrl from "../base_url";

class Footer extends Component {
  constructor(props) {
    super(props);
    var url = GetbaseUrl();
    this.state = {
      baseUrl: url,
      address: "",
      contactNumber: "",
      water_mark: "",
      news: {},
      loading: true,
    }
  }

  toggle = (menuItem) => {
    if (menuItem === "isHome") {
      $("#AreasWeCover").removeClass("act first");
      $("#isAboutUs").removeClass("act first");
      $("#services").removeClass("act first");
      $("#news").removeClass("act first");
      $("#contact_us").removeClass("act first");
      $("#testimonials").removeClass("act first");
      $("#isHome").addClass("act first");
    } else if (menuItem === "isAboutUs" || menuItem === "isAboutUsTrainingArticle" || menuItem === "isAboutUsTraining") {
      $("#isHome").removeClass("act first");
      $("#AreasWeCover").removeClass("act first");
      $("#services").removeClass("act first");
      $("#news").removeClass("act first");
      $("#contact_us").removeClass("act first");
      $("#testimonials").removeClass("act first");
      $("#isAboutUs").addClass("act first");
    } else if (menuItem === "AreasWeCover") {
      $("#isHome").removeClass("act first");
      $("#isAboutUs").removeClass("act first");
      $("#services").removeClass("act first");
      $("#news").removeClass("act first");
      $("#contact_us").removeClass("act first");
      $("#testimonials").removeClass("act first");
      $("#AreasWeCover").addClass("act first");
    } else if (menuItem === "services") {
      $("#isHome").removeClass("act first");
      $("#isAboutUs").removeClass("act first");
      $("#AreasWeCover").removeClass("act first");
      $("#news").removeClass("act first");
      $("#contact_us").removeClass("act first");
      $("#testimonials").removeClass("act first");
      $("#services").addClass("act first");
    } else if (menuItem === "news") {
      $("#isHome").removeClass("act first");
      $("#isAboutUs").removeClass("act first");
      $("#AreasWeCover").removeClass("act first");
      $("#services").removeClass("act first");
      $("#contact_us").removeClass("act first");
      $("#testimonials").removeClass("act first");
      $("#news").addClass("act first");
    } else if (menuItem === "testimonials") {
      $("#isHome").removeClass("act first");
      $("#isAboutUs").removeClass("act first");
      $("#AreasWeCover").removeClass("act first");
      $("#services").removeClass("act first");
      $("#news").removeClass("act first");
      $("#contact_us").removeClass("act first");
      $("#testimonials").addClass("act first");
    } else if (menuItem === "contact_us") {
      $("#isHome").removeClass("act first");
      $("#isAboutUs").removeClass("act first");
      $("#AreasWeCover").removeClass("act first");
      $("#services").removeClass("act first");
      $("#news").removeClass("act first");
      $("#testimonials").removeClass("act first");
      $("#contact_us").addClass("act first");
    }
  }
  componentDidMount() {
    this.getNewsApi();
  }
  getNewsApi = () => {

    let insertUrl = this.state.baseUrl + "getFooterData";
    // console.log(insertUrl);
    new Promise(function (resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST"
      }).then(
        function (data) {
          resolve(data);
        },
        function (err) {
          reject(err);
        }
      );
    })
      .then(result => {
        // console.log(result);
        this.setState({
          address: result['footerContent'][0]['address'],
          contactNumber: result['footerContent'][0]['phone'],
          water_mark: result['footerContent'][0]['water_mark'],
          news: result['news'],
          loading: false
        });

      })
      .catch(err => {
        console.log(err);
        //  this.setState({
        //    message: "No news Found!",
        //    isLoaded: true
        //  });
      });
  };

  render() {
    const { loading, news, address, contactNumber, water_mark } = this.state;
    return <React.Fragment>
      {/* Footer Start */}
      <footer id="footer" className="footer solid-bg">
        <div className="wf-wrap">
          <div className="wf-container-footer">
            <div className="wf-container">
              <section id="text-2" className="widget widget_text wf-cell wf-1-4">
                <div className="textwidget">
                  <p><img className="alignnone size-full wp-image-1841" src="http://firstserveuk.co.uk/admin/asset/img/footer/d8d19782dadfa54a12a2215ff80048de.png" alt="Logo - First Serve UK" width={350} height={130} /><br /> <a href="http://firstserveuk.simplybook.it/v2/" className="default-btn-shortcode dt-btn dt-btn-s btn-inline-left " id="orphaned-shortcode-35b8c400bd18bcc1a11010562ed31e4a"><span>24-Hour Online Booking!</span></a></p>
                </div>
              </section>
              <section id="presscore-contact-info-widget-3" className="widget widget_presscore-contact-info-widget wf-cell wf-1-4">
                <div className="widget-title">Contact Info</div>
                <ul className="contact-info">
                  <li><span className="color-primary">Address:</span><br />{address}</li>
                  {/* <li><span className="color-primary">Business hours:</span><br />07:00 AM - 21:00 PM</li> */}
                  <li><span className="color-primary">Phone number:</span><br /><a href="tel:07984 670782">{contactNumber}</a></li>
                </ul>
                <div className="soc-ico">
                  <p className="assistive-text">Find us on:</p>
                  <a title="Facebook" href="https://www.facebook.com/firstserveuk.co.uk/" target="_blank" rel="noopener noreferrer" className="facebook"><span className="soc-font-icon" /><span className="screen-reader-text">Facebook</span></a><a title="Twitter" href="https://twitter.com/1stserveuk" target="_blank" rel="noopener noreferrer" className="twitter"><span className="soc-font-icon" /><span className="screen-reader-text">Twitter</span></a><a title="Google+" href="https://plus.google.com/109301245344758908841" target="_blank" rel="noopener noreferrer" className="google"><span className="soc-font-icon" /><span className="screen-reader-text">Google+</span></a><a title="Instagram" href="https://www.instagram.com/firstserve_uk/" target="_blank" rel="noopener noreferrer" className="instagram"><span className="soc-font-icon" /><span className="screen-reader-text">Instagram</span></a>
                </div>
              </section>
              <section id="presscore-custom-menu-1-3" className="widget widget_presscore-custom-menu-1 wf-cell wf-1-4">
                <div className="widget-title">Services</div>
                <ul className="custom-menu show-arrow">
                  <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                    this.toggle("services")}>
                    <Link to="washing-machine-repair-london" data-level={2} onClick={this.linkCalled}>
                      <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs London</span></span></Link>
                  </li>
                  <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                    this.toggle("services")}>
                    <Link to="washing-machine-repair-kensington" data-level={2} onClick={this.linkCalled}>
                      <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs Kensington</span></span></Link>
                  </li>
                  <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                    this.toggle("services")}>
                    <Link to="washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                      <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs</span></span></Link>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2605" onClick={() =>
                    this.toggle("services")}>
                    <Link to="tumbler-and-condenser-dryers" data-level={2} onClick={this.linkCalled}>
                      <span className="menu-item-text"><span className="menu-text">Tumbler and Condenser Dryers</span></span></Link>
                  </li>

                  {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2638"><Link to="electric-cooker-and-oven-repa">Cookers</Link></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2640"><Link to="dishwashers">Dishwashers</Link></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2640"><a >Fridge repairs</a></li> */}
                </ul>
              </section>
              <section id="presscore-blog-posts-2" className="widget widget_presscore-blog-posts wf-cell wf-1-4">
                <div className="widget-title">Recent News</div>
                <ul className="recent-posts">
                  {loading == false ?
                    news.map((obj, index) => {
                      return (
                        <React.Fragment>
                          <li>

                            <article className="post-format-standard">
                              <div className="post-content"><Link to="news">{obj.name}</Link><br /><time className="text-secondary" dateTime="2019-02-24T18:46:16+01:00">{obj.created_at}</time></div>
                            </article>
                          </li>
                        </React.Fragment>
                      );
                    }

                    )
                    : ""
                  }
                </ul>
              </section>
            </div>
          </div>
        </div>
        <div id="bottom-bar" className="full-width-line logo-left" role="contentinfo">
          <div className="wf-wrap">
            <div className="wf-container-bottom">
              <div className="wf-float-left"> {water_mark}</div>
              <div className="wf-float-right">
                <div className="mini-nav">
                  <ul id="bottom-menu">
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1731 current_page_item menu-item-2595" id="isHome" onClick={() => this.toggle("isHome")}><Link to="/" data-level={1}><i className="fa fa-home" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Home</span></span></Link></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-2665 has-children" id="isAboutUs" onClick={() => this.toggle("isAboutUs")}>
                      <Link to="about-us" data-level={1}><i className="fa fa-address-card" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">About Us</span></span></Link>
                      <ul className="sub-nav">
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2598 " id="isAboutUsTrainingArticle" onClick={() =>
                          this.toggle("isAboutUsTrainingArticle")}><Link to="training-article" data-level={2}><span className="menu-item-text"><span className="menu-text">Training Article</span></span></Link></li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2597" id="isAboutUsTraining" onClick={() =>
                          this.toggle("isAboutUsTraining")}><Link to="training" data-level={2}><span className="menu-item-text"><span className="menu-text">Training</span></span></Link></li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-2599 has-children" id="AreasWeCover" onClick={() =>
                      this.toggle("AreasWeCover")}>
                      <Link to="areas-we-cover" data-level={1}><i className="fa fa-map-marker" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Areas We Cover</span></span></Link>
                      <ul className="sub-nav">
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                          this.toggle("AreasWeCover")}>
                          <Link to="washing-machine-repairs-in-w1-london" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In W1 London</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                          this.toggle("AreasWeCover")}>
                          <Link to="washing-machine-repairs-in-w2-london" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In W2 London</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                          this.toggle("AreasWeCover")}>
                          <Link to="washing-machine-repairs-in-chiswick-w4-london" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs In Chiswick W4 London </span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                          this.toggle("AreasWeCover")}>
                          <Link to="washing-machine-repairs-chelsea" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs Chelsea</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                          this.toggle("AreasWeCover")}>
                          <Link to="sw1w-and-sw1v-washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">SW1W And SW1V  Washing Machine Repairs</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2600 " onClick={() =>
                          this.toggle("AreasWeCover")}>
                          <Link to="sw10-washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">SW10 Washing Machine Repairs</span></span></Link>
                        </li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2600 " onClick={() =>
                          this.toggle("AreasWeCover")}><Link to="which-washing-machine" data-level={2}><span className="menu-item-text"><span className="menu-text">Which Washing Machine?</span></span></Link></li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2601" onClick={() =>
                          this.toggle("AreasWeCover")}><Link to="washer-dryer-efficiency" data-level={2}><span className="menu-item-text"><span className="menu-text">Washer Dryer Efficiency</span></span></Link></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2602" onClick={() =>
                          this.toggle("AreasWeCover")}><a data-level={2}><span className="menu-item-text"><span className="menu-text">Save Money</span></span></a></li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-2603 has-children" id="services" onClick={() =>
                      this.toggle("services")}>
                      <Link to="services" data-level={1}><i className="fa fa-folder-open" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Our Services</span></span></Link>
                      <ul className="sub-nav">
                        <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                          this.toggle("services")}>
                          <Link to="washing-machine-repair-london" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs London</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                          this.toggle("services")}>
                          <Link to="washing-machine-repair-kensington" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs Kensington</span></span></Link>
                        </li>
                        <li className="menu-item  menu-item-object-page menu-item-2604" onClick={() =>
                          this.toggle("services")}>
                          <Link to="washing-machine-repairs" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">Washing Machine Repairs</span></span></Link>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2605" onClick={() =>
                          this.toggle("services")}>
                          <Link to="tumbler-and-condenser-dryers" data-level={2} onClick={this.linkCalled}>
                            <span className="menu-item-text"><span className="menu-text">Tumbler and Condenser Dryers</span></span></Link>
                        </li>
                        {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2608" onClick={() =>
                        this.toggle("services")}><Link to="electric-cooker-and-oven-repa" data-level={2}><span className="menu-item-text"><span className="menu-text">Cookers</span></span></Link></li> */}
                        {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2611" onClick={() =>
                        this.toggle("services")}><Link to="dishwashers" data-level={2}><span className="menu-item-text"><span className="menu-text">Dishwashers</span></span></Link></li> */}
                        {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2610" onClick={() =>
                        this.toggle("services")}><a  data-level={2}><span className="menu-item-text"><span className="menu-text">Fridge repairs</span></span></a></li> */}
                      </ul>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2612" id="news" onClick={() =>
                      this.toggle("news")}><Link to="news" data-level={1}><i className="fa fa-newspaper-o" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">News</span></span></Link></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2613" id="testimonials" onClick={() =>
                      this.toggle("testimonials")}><Link to="testimonials" data-level={1}><i className="fa fa-thumbs-o-up" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Testimonials</span></span></Link></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2614" id="contact_us" onClick={() =>
                      this.toggle("contact_us")}><Link to="contact-us" data-level={1}><i className="fa fa-phone" aria-hidden="true" /><span className="menu-item-text"><span className="menu-text">Contact</span></span></Link></li>
                  </ul>
                  <div className="menu-select"><span className="customSelect1"><span className="customSelectInner">Primary Menu</span></span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <a style={{ marginBottom: "70px" }} className="scroll-top"><span className="screen-reader-text">Go to Top</span></a>
      {/* #END# Footer End */}
    </React.Fragment>;
  }
};
export default Footer;
