import React, { Component } from "react";

class Reviews extends Component {
  componentDidMount() {
    var loadjs = require("loadjs");
    // loadjs(["./assets/js/googleReviews.js"], function() {});
    loadjs(["./assets/js/googleReviews.js"]);
  }
  render() {
    return (
      <React.Fragment>
        <div className="cs-col-1 wpb_column vc_column_container vc_col-sm-9">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div className="wpb_widgetised_column wpb_content_element sidebar-content solid-bg">
                <div className="wpb_wrapper">
                  <section id="text-11" className="widget widget_text">
                    <div className="widget-title">
                      We have over 400, 5 star reviews
                    </div>
                    <div className="textwidget">
                      <div id="google-reviews" />
                      <p>
                        <a
                          href="https://www.google.com/maps/place/First+Serve+Uk+Washing+Machine+Repairs/@51.481909,-0.175743,12z/data=!4m5!3m4!1s0x0:0xee8e6b6b45c9e1e0!8m2!3d51.481909!4d-0.175743"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="google-reviews-image alignnone size-full wp-image-2882"
                            src="wp-content/uploads/2019/01/Google-reviews2.png"
                            alt="First Serve UK"
                          />
                          <br />{" "}
                          <span
                            style={{ textAlign: "center", display: "block" }}
                          >
                            See all reviews please
                          </span>
                          <br />{" "}
                        </a>
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Reviews;
